import React, { useEffect, useRef, useState } from "react";
import { BrushColor } from "../../common/interfaces";

interface ColorPickerProps {
  displayMode: "large" | "small";
  colors: BrushColor[];
  activeColor: BrushColor["id"];
  setBrushColor: (color: BrushColor["id"]) => void;
}

const BrushColorPickerComponent: React.FunctionComponent<ColorPickerProps> = (
  props
) => {
  const ref = useRef<HTMLDivElement>();
  const [top, setTop] = useState(undefined);

  useEffect(() => {
    if (!ref.current) {
      return;
    }
    setTop(ref.current.getBoundingClientRect().top);
  });

  const componentStyle =
    props.displayMode === "large"
      ? { overflowY: "scroll", height: `calc(100vh - ${top}px)` }
      : {
          overflowX: "scroll",
        };

  return (
    <div ref={ref} style={componentStyle as any}>
      <div
        style={{
          display: "flex",
          flexWrap: props.displayMode === "large" ? "wrap" : "nowrap",
        }}
      >
        {props.colors.map((color, i) => {
          const cssColor = `rgb(${color.colorValue[0]}, ${color.colorValue[1]}, ${color.colorValue[2]})`;
          const selected = color.id === props.activeColor;

          const brushColorWrapperStyle =
            props.displayMode === "large"
              ? {
                  width: "50%",
                  paddingLeft: i % 2 === 0 ? "0px" : "5px",
                  paddingRight: i % 2 === 0 ? "5px" : "0px",
                  paddingTop: "5px",
                  paddingBottom: "5px",
                }
              : {
                  width: "100%",
                  padding: "5px",
                };

          return (
            <div key={i} style={brushColorWrapperStyle}>
              <div
                onClick={() => {
                  props.setBrushColor(color.id);
                }}
                className="brush-color"
                style={{
                  padding: "16",
                  background: cssColor,
                  borderRadius: "8px",
                  boxShadow: selected
                    ? `inset 0px 0px 0px 5px rgb(13,110,253)`
                    : "none",
                }}
              >
                <div
                  style={{
                    background: "rgba(255,255,255,1)",
                    color: selected ? "#333" : "#888",
                    width: "40px",
                    borderRadius: "8px",
                    margin: "0px auto",
                  }}
                >
                  <p className="text-center mb-0">
                    <strong>{color.number}</strong>
                  </p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default BrushColorPickerComponent;
