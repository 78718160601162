import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { PriceString } from "../checkout";
import { Pricing, SignUpButton } from "../components/Pricing";
import { FancyTitle } from "../components/FancyTitle";

function VideoPlayer(props) {
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null) as any;

  const handlePlay = () => {
    videoRef?.current.play();
    setIsPlaying(true);
  };

  return (
    <div
      style={{
        position: "relative",
        borderRadius: "8px",
        background: "white",
        width: "100%",
      }}
    >
      <video
        style={{
          width: "100%",
          border: "3px solid white",
          borderRadius: "8px",
        }}
        ref={videoRef}
        preload="auto"
        controls={isPlaying}
      >
        <source
          src="https://pbnassets-dev.nyc3.cdn.digitaloceanspaces.com/web-assets/PBN-in-action.mp4#t=0.001"
          type="video/mp4"
        />
      </video>
      {!isPlaying && (
        <div
          style={{
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "#00000077",
            border: "3px solid white",
            borderRadius: "8px",
          }}
        >
          <button className="btn btn-primary" onClick={handlePlay}>
            <i className="me-2 bi bi-play-circle-fill"></i>
            See it in action
          </button>
        </div>
      )}
    </div>
  );
}

function Hero(props) {
  return (
    <div
      style={{
        width: "100%",
        background:
          "url('https://pbnassets-dev.nyc3.cdn.digitaloceanspaces.com/thumbs/eb3a8445-e7d6-45e9-ac49-9fd2dd71a9ce-thumb.jpg')",
        backgroundSize: "cover",
      }}
    >
      <div
        style={{
          background: "#6190E888",
        }}
      >
        <div className="container py-5">
          <div className="row align-items-center my-5">
            <div className="col-md-6">
              <div className="mb-5">
                <h1
                  style={{
                    fontSize: "3rem",
                  }}
                  className="mb-3 text-white"
                >
                  <strong>Find your Oasis.</strong>
                </h1>
                <h2 className="text-white">
                  <strong>A digital paint-by-numbers experience.</strong>
                </h2>
              </div>

              <div className="d-flex">
                <Link to="/sign-up" className="me-3">
                  <button className="btn btn-primary btn-lg mb-2">
                    <strong>Sign Up</strong>
                  </button>
                </Link>
                <Link to="/gallery">
                  <button className="btn btn-light btn-lg mb-2">
                    <strong>Try the Demo</strong>{" "}
                    <i className="bi bi-arrow-right"></i>
                  </button>
                </Link>
              </div>
              <p className="text-light">
                <small>{PriceString}/ mo. Ad free. Cancel anytime.</small>
              </p>
            </div>
            <div className="col-md-6">
              <VideoPlayer />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function HowItWorksSection({
  imageUrl,
  title,
  followUp,
}: {
  imageUrl: string;
  title: string;
  followUp: string;
}) {
  return (
    <div className="col-md-12 col-lg-4 mb-5 p-4">
      <p className="lead">{title}</p>
      <p className="text-secondary">
        <small>{followUp}</small>
      </p>
      <img
        src={imageUrl}
        style={{
          width: "100%",
          height: "400px",
          background: "#eee",
          margin: "0 auto",
          borderRadius: "16px",
          objectFit: "cover",
          boxShadow: "0px 5px 10px #00000033",
        }}
      />
    </div>
  );
}

function HowItWorks(props) {
  return (
    <div className="container py-5">
      <div className="row mb-5">
        <div className="col">
          <FancyTitle>How it works</FancyTitle>
          <h2>
            <strong>It's easy to get started</strong>
          </h2>
        </div>
      </div>
      <div className="row text-center mb-5">
        <HowItWorksSection
          title="Start from any device"
          followUp="Works great on your laptop, iPad, or phone."
          imageUrl="https://pbnassets-dev.nyc3.cdn.digitaloceanspaces.com/web-assets/ipad.jpg"
        />
        <HowItWorksSection
          title="Choose a painting"
          followUp="Find something that matches your mood."
          imageUrl="https://pbnassets-dev.nyc3.cdn.digitaloceanspaces.com/thumbs/3a5c83ec-94e4-4cfd-89a0-0ea2489327fc-thumb.jpg"
        />
        <HowItWorksSection
          title="Create your masterpiece"
          followUp="Calm your mind, color your canvas."
          imageUrl="https://pbnassets-dev.nyc3.cdn.digitaloceanspaces.com/web-assets/ipad-painting.jpg"
        />
      </div>
    </div>
  );
}

function FreshImages(props) {
  const thumbs = [
    "https://pbnassets-dev.nyc3.cdn.digitaloceanspaces.com/thumbs/227a5bec-33ac-4fab-8d9c-654fd4da9fb8-thumb.jpg",
    "https://pbnassets-dev.nyc3.cdn.digitaloceanspaces.com/thumbs/4de55695-9ae9-4312-9ecd-b018ae651d92-thumb.jpg",
    "https://pbnassets-dev.nyc3.cdn.digitaloceanspaces.com/thumbs/eb3a8445-e7d6-45e9-ac49-9fd2dd71a9ce-thumb.jpg",
    "https://pbnassets-dev.nyc3.cdn.digitaloceanspaces.com/thumbs/ba7aa380-d732-4dda-9e9a-d8d79b256913-thumb.jpg",

    "https://pbnassets-dev.nyc3.cdn.digitaloceanspaces.com/thumbs/c8fbb4ae-28a6-468c-9c93-715390a35ee0-thumb.jpg",
    "https://pbnassets-dev.nyc3.cdn.digitaloceanspaces.com/thumbs/9c9ee918-20de-4152-8b5d-1b6b2fb11b55-thumb.jpg",
    "https://pbnassets-dev.nyc3.cdn.digitaloceanspaces.com/thumbs/89e8bd2a-9851-4c14-8eba-d3c49f472c6b-thumb.jpg",
    "https://pbnassets-dev.nyc3.cdn.digitaloceanspaces.com/thumbs/9c3f6c7a-277f-420d-ba9a-d9bf5e04a918-thumb.jpg",
  ];

  return (
    <>
      {thumbs.map((url, i) => {
        return (
          <div className="col-md-3 mb-4" key={i}>
            <div className={props.reveal ? "reveal active" : "reveal"}>
              <img
                className="zoom"
                style={{
                  width: "100%",
                  height: "200px",
                  borderRadius: "8px",
                  objectFit: "cover",
                  boxShadow: "0px 5px 20px rgba(0.0, 0.0, 0.0, .2)",
                }}
                src={url}
              ></img>
            </div>
          </div>
        );
      })}
    </>
  );
}

class BreathOfFreshAir extends React.Component {
  ref: React.RefObject<HTMLDivElement>;

  state = {
    showImages: false,
  };

  constructor(props) {
    super(props);
    this.ref = React.createRef();
  }

  reveal = () => {
    if (!this.ref.current) {
      return;
    }
    const y = this.ref.current!.getBoundingClientRect().top;
    if (y <= 650) {
      this.setState({ showImages: true });
    }
  };

  componentDidMount(): void {
    window.addEventListener("scroll", this.reveal);
  }

  componentWillUnmount(): void {
    window.removeEventListener("scroll", this.reveal);
  }

  render() {
    return (
      <div
        style={{
          background: "linear-gradient(180deg, #6190E8 0%, #fff 100%)",
        }}
      >
        <div className="container text-light py-5">
          <div className="row mb-5">
            <div className="col">
              <FancyTitle variant="light">Hand selected</FancyTitle>
              <h2>
                <strong>New paintings added every month</strong>
              </h2>
            </div>
          </div>
          <div className="row py-4" ref={this.ref}>
            <FreshImages reveal={this.state.showImages} />
          </div>
          <div className="row py-4 justify-content-center">
            <div className="col-auto align-self-center">
              <Link to="/gallery">
                <button className="btn btn-primary">
                  Explore the gallery <i className="bi bi-arrow-right"></i>
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function Benefit(props) {
  return (
    <div className="col-lg-4 p-4">
      <div className="d-flex align-items-center">
        <img
          style={{
            width: "120px",
            height: "120px",
            borderRadius: "100%",
            backgroundColor: "#eee",
          }}
          src={props.svg}
        ></img>

        <h4 className="mx-4">{props.title}</h4>
      </div>
      <p className="my-5">{props.description}</p>
    </div>
  );
}

function Benefits(props) {
  const benefits = [
    {
      svg: require("../../../assets/mindful.svg"),
      title: "Quiet your mind",
      description:
        "Whether you're feeling anxious, or you've had a long day, Oasis PBN can help you find a sense of calm.",
    },
    {
      svg: require("../../../assets/artistic.svg"),
      title: "Engage your artistic side",
      description:
        "Experience the joy and satisfaction of creating art, whether you’re a beginner or a skilled painter.",
    },
    {
      svg: require("../../../assets/restless.svg"),
      title: "Channel restless energy",
      description:
        "Wonderful for when you need something to do with your hands while watching TV or listening to a podcast.",
    },
  ];

  return (
    <div className="container py-5 my-5">
      <div className="row">
        {benefits.map((b, i) => (
          <Benefit key={i} {...b} />
        ))}
      </div>
    </div>
  );
}

function Testimonials(props) {
  return (
    <div
      style={{
        width: "100%",
        background:
          "url('https://pbnassets-dev.nyc3.cdn.digitaloceanspaces.com/thumbs/eb3a8445-e7d6-45e9-ac49-9fd2dd71a9ce-thumb.jpg')",
        backgroundSize: "cover",
      }}
    >
      <div style={{ width: "100%", background: "rgba(255, 255, 255, .5)" }}>
        <div className="container py-5">
          <div className="row mb-5">
            <div className="col">
              <FancyTitle>People love Oasis</FancyTitle>
              <h2>
                <strong>Hear from our happy customers</strong>
              </h2>
            </div>
          </div>

          <div
            className="my-5"
            style={{
              background: "rgba(255, 255, 255, .9)",
              padding: "40px",
              borderRadius: "8px",
              boxShadow: "0px 10px 20px rgba(0.0, 0.0, 0.0, .1)",
            }}
          >
            <div className="row">
              <div className="col-md-6 g-5">
                <p className="lead mb-5">
                  "I really think this is the best paint-by-numbers app I've
                  tried. I really love the selection to choose from, and how I
                  actually get to paint instead of just tapping!"
                </p>
                <p className="text-secondary">Rebecca G. Brooklyn, NY</p>
              </div>
              <div className="col-md-6 g-5">
                <p className="lead mb-5">
                  "Doing this on my laptop while I watch tv is so much more
                  enjoyable than scrolling on social media. I feel like I
                  actually chill out when I'm trying to chill out."
                </p>
                <p className="text-secondary">Megan R. Atlanta, GA</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function Feature(props) {
  return (
    <div className="col-md-4 mb-4">
      <div className="d-flex align-items-baseline">
        <i className="bi bi-check-circle text-success"></i>
        <div className="ms-3">
          <p className="mb-1">
            <strong>{props.title}</strong>
          </p>
          <p className="text-secondary">{props.description}</p>
        </div>
      </div>
    </div>
  );
}

export class MarketingPage extends React.Component {
  render() {
    return (
      <div>
        {/* hero */}
        <Hero />

        {/* how it works */}
        <HowItWorks />

        {/* breath of fresh air */}
        <BreathOfFreshAir />

        {/* benefits */}
        <Benefits />

        {/* testimonials */}
        <Testimonials />

        {/* pricing */}
        <Pricing>
          <SignUpButton />
        </Pricing>
      </div>
    );
  }
}
