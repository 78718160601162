import React, { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { SubscriptionStatus } from "../../common/interfaces";
import { useWindowDimensions } from "./useWindowDimensions";

interface NavBarProps {
  authenticated: boolean;
  subscriptionStatus?: SubscriptionStatus;
  signOut?: () => void;
}

const noUnderline = { textDecoration: "none" };

const NavBarShell = (props) => {
  let className = props.isFluid ? "container-fluid" : "container";
  if (props.isPadded) {
    className += " px-5";
  }

  return (
    // <div style={{ background: "linear-gradient(to left, #6190E8, #6190E8)" }}>
    <div style={{background: "#6190E8"}}>
      <div className={className}>
        <div
          className="row d-flex align-items-center "
          style={{ height: "48px" }}
        >
          {props.children}
        </div>
      </div>
    </div>
  );
};

function NavLink(props) {
  if (props.useButton) {
    return (
      <Link
        className="text-light mx-1"
        to={props.to}
        style={noUnderline}
        onClick={props.onClick}
      >
        <button className="btn btn-outline-light">{props.children}</button>
      </Link>
    );
  }

  return (
    <Link
      className="text-light mx-2"
      to={props.to}
      style={noUnderline}
      onClick={props.onClick}
    >
      {props.children}
      {props.label}
    </Link>
  );
}

export const NavBar: FunctionComponent<NavBarProps> = (props) => {
  if (!props.authenticated) {
    return (
      <NavBarShell isFluid={true}>
        <div className="col-8">
          <Link className="fancy text-light" to="/" style={noUnderline}>
            🌴 Oasis
          </Link>
          <Link className="text-light ms-4" to="/gallery" style={noUnderline}>
            Gallery
          </Link>
          <Link className="text-light ms-4" to="/about" style={noUnderline}>
            About
          </Link>
        </div>
        <div className="col-4 text-end">
          <Link to={"/sign-in"}>
            <button className="btn btn-outline-light" style={noUnderline}>
              Sign In
            </button>
          </Link>
        </div>
      </NavBarShell>
    );
  }

  // I don't like this.
  const { width, height } = useWindowDimensions();
  const useButtons = width <= 768;
  return (
    <NavBarShell isFluid={true} isPadded={false}>
      <div className="col-8">
        <Link className="fancy text-light me-2" to="/" style={noUnderline}>
          🌴 Oasis
        </Link>

        <NavLink label={"Gallery"} to={"/"} useButton={useButtons}>
          <i className="bi bi-grid-3x2-gap-fill mx-1"></i>
        </NavLink>

        <NavLink
          label={"My Paintings"}
          to={"/my-paintings"}
          useButton={useButtons}
        >
          <i className="bi bi-palette-fill mx-1"></i>
        </NavLink>
      </div>

      <div className="col-4 d-flex justify-content-end">
        <NavLink label={"Account"} to={"/account"} useButton={useButtons}>
          <i className="bi bi-person-fill mx-1"></i>
        </NavLink>

        <NavLink
          label={"Sign Out"}
          to={"#"}
          onClick={() => props.signOut!()}
          useButton={useButtons}
        >
          <i className="bi bi-arrow-bar-right mx-1"></i>
        </NavLink>
      </div>
    </NavBarShell>
  );
};
