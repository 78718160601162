import React from "react";

interface ModalProps extends React.PropsWithChildren {
  open: boolean;
  close: () => void;
}

export class Modal extends React.Component<ModalProps> {
  render() {

    if (!this.props.open) {
      return null;
    }

    return (
      <div
        onClick={(e) => {
          console.log("clsoe")
          this.props.close();
        }}
        style={{
          position: "fixed",
          width: "100%",
          height: "100%",
          background: "rgba(0,0,0,.8)",
          top: "0",
          left: "0",
          zIndex: "10000",
        }}
      >
        {/* modal scrim */}
        <div
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          style={{
            position: "relative",
            top: "10vh",
            width: "70%",
            minWidth: "375px",
            maxHeight: "80vh",
            backgroundColor: "white",
            borderRadius: "8px",
            margin: "auto",
            padding: "40px",
            overflowY: "scroll",
          }}
        >
          {/* modal body */}
          <div>
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }
}
