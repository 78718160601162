import { glMatrix, mat4, quat, vec2, vec3, vec4 } from "gl-matrix";
import React from "react";
import REGL from "regl";
import { BrushColor, ChunkBlob } from "../../common/interfaces";
import rayIntersect from "../math/ray";
import unproject from "../math/unproject";
import annulus from "../geometry/annulus";
import { ImageDataContainer, getImageData } from "../imageDataContainer";
import { loadImage } from "../loadImage";
import camera, { MaxCameraDistance, MinCameraDistance } from "./camera";


const quad = {
  position: [
    [-0.5, 0.5, 0.0],
    [0.5, 0.5, 0.0],
    [0.5, -0.5, 0.0],
    [-0.5, -0.5, 0.0],
  ],
  cells: [0, 1, 2, 0, 2, 3],
  uv: [0, 0, 1, 0, 1, 1, 0, 1],
};

function lerp(v0, v1, t) {
  return (1 - t) * v0 + t * v1;
}

// assume integer positions
function drawCircle(
  originX: number,
  originY: number,
  radius: number,
  color: BrushColor["colorValue"],
  eraserActive: boolean,
  imageData: Uint8ClampedArray,
  shadowImageData: Uint8ClampedArray,
  textureWidth: number
) {
  for (let i = originX - radius; i < originX + radius + 1; i++) {
    for (let j = originY - radius; j < originY + radius + 1; j++) {
      // prevent over-draw that wraps around to the other side
      if (i >= textureWidth || i < 0) {
        continue;
      }
      // find distance from (i, j) to origin
      const distance = vec2.distance([i, j], [originX, originY]);
      let diff = distance - radius;
      // t = diff
      // lerp from 0 to 1
      // when t is 0 -> 0
      // when t is 1+ -> 1
      diff = Math.min(diff, 1);
      diff = Math.max(diff, 0);
      const index = i * 4 + j * 4 * textureWidth;

      if (eraserActive) {
        const r0 = shadowImageData[index];
        const g0 = shadowImageData[index + 1];
        const b0 = shadowImageData[index + 2];
        const a0 = shadowImageData[index + 3];

        imageData[index] = lerp(r0, imageData[index], diff);
        imageData[index + 1] = lerp(g0, imageData[index + 1], diff);
        imageData[index + 2] = lerp(b0, imageData[index + 2], diff);
        imageData[index + 3] = lerp(a0, imageData[index + 3], diff);
      } else {
        const r0 = imageData[index];
        const g0 = imageData[index + 1];
        const b0 = imageData[index + 2];
        const a0 = imageData[index + 3];

        imageData[index] = lerp(color[0], r0, diff);
        imageData[index + 1] = lerp(color[1], g0, diff);
        imageData[index + 2] = lerp(color[2], b0, diff);
        imageData[index + 3] = lerp(255, a0, diff);
      }
    }
  }
}



function DrawTexture(reglInstance) {
  return reglInstance({
    vert: `
      precision highp float;
      attribute vec3 position;
      attribute vec2 uv;
      varying vec2 vUv;
      uniform mat4 projection, view, model;
      void main () {
        vUv = uv;
        gl_Position = projection * view * model * vec4(position, 1.0);
      }
    `,
    frag: `
      precision highp float;
      varying vec2 vUv;
      uniform sampler2D tex;
      void main() {
        vec4 color = texture2D(tex, vUv);
        gl_FragColor = color;
      }
    `,
    attributes: {
      position: quad.position,
      uv: quad.uv,
    },
    uniforms: {
      projection: (_, props) => props.projection,
      view: (_, props) => props.view,
      model: (_, props) => props.model,
      tex: (_, props) => props.texture,
    },
    elements: quad.cells,
  });
}

export interface PaintCanvasProps {
  canvasHeight: string; // css string e.g. "500px"
  enabled: boolean;
  blobs: ChunkBlob[];
  templateUrl: string;
  brushSize: number;
  brushColor: BrushColor;
  mode: "brush" | "eraser" | "pan";
  save: (idc: ImageDataContainer) => void;
  magicallyFinished: boolean;
  previewUrl: string;
}

interface PaintCanvasState {
  cursorStyle: "none" | "move";
}

const PaintCursor = "none";
const PanCursor = "move";
export class PaintCanvas extends React.Component<
  PaintCanvasProps,
  PaintCanvasState
> {
  canvasRef: React.RefObject<HTMLCanvasElement>;
  regl: REGL.Regl;
  projectionMatrix: mat4;
  textureWidth: number;
  textureHeight: number;
  mousePressed: boolean;
  reglframe: REGL.Cancellable;
  shadowImageData: Uint8ClampedArray;
  lastTouchX: number = 0;
  lastTouchY: number = 0;
  lastScale: number;
  cursorTransform: mat4;
  cursorPos: { x: number; y: number };
  usesCursor: boolean;

  // The texture is divided into vertical blit chunks. A dirty blit chunk indicates
  // the corresponding part of the texture should be re-drawn.
  blitChunks: { yMin: number; yMax: number; dirty: boolean }[];
  idc: ImageDataContainer;
  isTouchScreen: boolean;

  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
    this.state = {
      cursorStyle: PaintCursor,
    };
    this.cursorPos = { x: 0, y: 0 };
    this.cursorTransform = mat4.create();
    this.usesCursor = false;
    camera.reset();
  }

  updateCanvasDimensions = () => {
    const canvas = this.canvasRef.current!;
    const rect = canvas.getBoundingClientRect();
    const scale = window.devicePixelRatio;
    canvas.width = Math.floor(scale * rect.width);
    canvas.height = Math.floor(scale * rect.height);

    // update projection matrix
    this.projectionMatrix = mat4.perspective(
      mat4.create(),
      glMatrix.toRadian(45),
      canvas.width / canvas.height,
      0.01,
      1000
    );
  };

  componentDidUpdate(prevProps: Readonly<PaintCanvasProps>, prevState: Readonly<PaintCanvasState>, snapshot?: any): void {
    if (prevProps.canvasHeight !== this.props.canvasHeight) {
      this.updateCanvasDimensions();
    }
  }

  async componentDidMount() {
    // Init blit chunks here incase the user clicks on the canvas while the painting is loading.
    this.blitChunks = [];
    this.lastScale = 1.0;

    this.regl = REGL({
      canvas: this.canvasRef.current!,
      attributes: { antialias: true },
    });

    window.addEventListener("resize", this.updateCanvasDimensions);
    this.updateCanvasDimensions();

    const template = await loadImage(this.props.templateUrl);
    this.textureWidth = template.width;
    this.textureHeight = template.height;
    this.shadowImageData = getImageData(template);

    console.log(`dimensions: (${this.textureWidth}, ${this.textureHeight})`);

    this.idc = new ImageDataContainer(this.textureWidth, this.textureHeight);
    await this.idc.initFromTemplateAndBlobs(template, this.props.blobs);

    if (this.props.magicallyFinished) {
      const preview = await loadImage(this.props.previewUrl);
      this.idc.magicallyFinishPainting(preview);
    }

    // Create blit chunks.
    for (let y = 0; y < 16; y++) {
      const chunk = {
        dirty: true,
        yMin: y * Math.ceil(this.textureHeight / 16),
        yMax:
          y === 15
            ? this.textureHeight
            : (y + 1) * Math.ceil(this.textureHeight / 16),
      };
      this.blitChunks.push(chunk);
    }

    const annulusgeo = annulus();
    const drawCircleCursor = this.regl({
      vert: `
        precision highp float;
        attribute vec3 position;
        uniform mat4 projection, view, model;
        void main() { 
          gl_Position = projection * view * model * vec4(position, 1.0);
        }
      `,
      frag: `
        precision highp float;
        void main () {
          gl_FragColor = vec4(1.0, 0.0, 0.0, 1.0);
        }
      `,
      uniforms: {
        projection: (_, props: any) => props.projection,
        view: (_, props) => props.view,
        model: (_, props) => props.model,
      },
      attributes: {
        position: annulusgeo.positions,
      },
      elements: annulusgeo.cells,
    });

    // model scaling.
    // the quad should have the same aspect ratio as the image
    // we can scale to get us there.
    const imageAspect = this.textureWidth / this.textureHeight;
    const modelScaleY = 1;
    const modelScaleX = imageAspect * modelScaleY;
    const modelMatrix = mat4.fromScaling(mat4.create(), [
      modelScaleX,
      modelScaleY,
      1,
    ]);
    const texture = this.regl.texture({
      width: this.textureWidth,
      height: this.textureHeight,
    });
    const drawTexture = DrawTexture(this.regl);

    this.reglframe = this.regl.frame(() => {
      this.regl.clear({ color: [0.9, 0.9, 0.9, 1] });

      if (
        this.usesCursor &&
        (this.props.mode === "brush" || this.props.mode === "eraser")
      ) {
        const sy = (this.props.brushSize / this.textureHeight) * 2;
        mat4.fromRotationTranslationScale(
          this.cursorTransform,
          quat.create(),
          [this.cursorPos.x, this.cursorPos.y, 0],
          [sy, sy, 0.0]
        );
        drawCircleCursor({
          model: this.cursorTransform,
          view: camera.viewMatrix,
          projection: this.projectionMatrix,
        });
      }

      // Check for dirty blit chunks and update the texture.
      for (const blitChunk of this.blitChunks) {
        if (!blitChunk.dirty) {
          continue;
        }
        blitChunk.dirty = false;
        texture.subimage(
          {
            width: this.textureWidth,
            height: blitChunk.yMax - blitChunk.yMin,
            data: this.idc.data.subarray(
              this.textureWidth * 4 * blitChunk.yMin,
              this.textureWidth * 4 * blitChunk.yMax
            ),
          },
          0,
          blitChunk.yMin
        );
      }

      drawTexture({
        texture: texture,
        model: modelMatrix,
        view: camera.viewMatrix,
        projection: this.projectionMatrix,
      });
    });

    const c = this.canvasRef.current!;
    c.addEventListener("gesturestart", this.gestureStartHandler);
    c.addEventListener("gesturechange", this.gestureChangeHandler);
    c.addEventListener("gestureend", this.gestureEndHandler);

    // works to prevent page zoom in chrome on pinch
    // pinch gesture in chrome is treated as a wheel event
    c.addEventListener("wheel", this.wheelHandler, {
      passive: false,
    });

    window.addEventListener("focus", this.onFocusMobileSafari);
  }

  onFocusMobileSafari = () => {
    requestAnimationFrame(() => {
      this.regl.clear({ color: [0.9, 0.9, 0.9, 1] });
    });
  };

  gestureStartHandler = (e) => {
    e.preventDefault();
    this.isTouchScreen = true;
  };

  gestureChangeHandler = (e) => {
    e.preventDefault();
    if (this.props.mode === "pan") {
      const d = e.scale - this.lastScale;
      this.zoom(d > 0 ? -0.025 : 0.025);
      this.lastScale = e.scale;
    }
  };

  gestureEndHandler = (e) => {
    e.preventDefault();
  };

  wheelHandler = (e) => {
    e.preventDefault();
    // e.stopPropagation(); // do i need this?
    this.zoom(e.deltaY / 1000.0);
  };

  componentWillUnmount(): void {
    if (this.reglframe) {
      this.reglframe.cancel();
    }
    const c = this.canvasRef.current!;
    c.removeEventListener("wheel", this.wheelHandler);
    c.removeEventListener("gesturestart", this.gestureStartHandler);
    c.removeEventListener("gesturechange", this.gestureChangeHandler);
    c.removeEventListener("gestureend", this.gestureEndHandler);
    window.removeEventListener("focus", this.onFocusMobileSafari);
    window.removeEventListener("resize", this.updateCanvasDimensions);
  }

  updateCursor = (x, y) => {
    this.usesCursor = true;

    if (this.projectionMatrix === undefined) {
      return;
    }

    const canvasWidth = this.canvasRef.current!.width / window.devicePixelRatio;
    const canvasHeight =
      this.canvasRef.current!.height / window.devicePixelRatio;
    const rayDirection = unproject(
      x,
      y,
      this.projectionMatrix,
      camera.viewMatrix,
      canvasWidth,
      canvasHeight
    );
    const rayOrigin = camera.eye;
    const { intersects, t } = rayIntersect(rayOrigin, rayDirection, {
      minX: -10, // arbitrarily large.
      maxX: 10,
      minY: -10,
      maxY: 10,
      minZ: 0,
      maxZ: 0.00001,
    });
    if (intersects) {
      const intersectPos = vec3.create();
      vec3.scale(intersectPos, rayDirection, t);
      vec3.add(intersectPos, intersectPos, rayOrigin);

      let x = intersectPos[0];
      let y = -intersectPos[1];

      this.cursorPos.x = x;
      this.cursorPos.y = -y;
    }
  };

  paint = (x, y) => {
    if (!this.props.enabled) {
      return;
    }

    if (this.projectionMatrix === undefined) {
      return;
    }

    const canvasWidth = this.canvasRef.current!.width / window.devicePixelRatio;
    const canvasHeight =
      this.canvasRef.current!.height / window.devicePixelRatio;
    const rayDirection = unproject(
      x,
      y,
      this.projectionMatrix,
      camera.viewMatrix,
      canvasWidth,
      canvasHeight
    );
    const rayOrigin = camera.eye;

    const imageAspect = this.textureWidth / this.textureHeight;
    const modelScaleY = 1;
    const modelScaleX = imageAspect * modelScaleY;

    const { intersects, t } = rayIntersect(rayOrigin, rayDirection, {
      minX: -0.5 * modelScaleX,
      maxX: 0.5 * modelScaleX,
      minY: -0.5,
      maxY: 0.5,
      minZ: 0,
      maxZ: 0.00001,
    });
    if (intersects) {
      const intersectPos = vec3.create();
      vec3.scale(intersectPos, rayDirection, t);
      vec3.add(intersectPos, intersectPos, rayOrigin);

      let x = intersectPos[0];
      let y = -intersectPos[1];

      // map from [-.5, .5] to [0, 1]
      // consider scale
      x = x + 0.5 * modelScaleX;
      y = y + 0.5 * modelScaleY;

      // map from [0, 1] to [0, 1023]
      x = Math.ceil((x * (this.textureWidth - 1)) / modelScaleX);
      y = Math.ceil((y * (this.textureHeight - 1)) / modelScaleY);

      drawCircle(
        x,
        y,
        this.props.brushSize,
        this.props.brushColor.colorValue,
        this.props.mode === "eraser",
        this.idc.data,
        this.shadowImageData,
        this.textureWidth
      );

      // Find blit chunks that this stroke dirties.
      // We should consider the brush size here, not just the center of the brush.
      for (const chunk of this.blitChunks) {
        const brushTopCheck =
          y - this.props.brushSize >= chunk.yMin &&
          y - this.props.brushSize < chunk.yMax;

        const brushBottomCheck =
          y + this.props.brushSize >= chunk.yMin &&
          y + this.props.brushSize < chunk.yMax;

        if (brushTopCheck || brushBottomCheck) {
          chunk.dirty = true;
        }
      }

      // figure out what data chunks are dirty
      // If I use indexes, then I'm locking the schema
      // it might be better if I do it based on
      // then I have the image data
      // based on the the stroke position, and the brush size,
      // we are effecting some image chunk.
      // Calculate the chunk index effected
      // for each effected chunk, collect the image data of that chunk
      // write to a canvas, and get the blob

      // I think the hard part is collecting image data when the image data
      // is not contiguous. If the chunk isn't the full width.
      this.idc.setChunksDirty(x, y, this.props.brushSize);
      this.props.save(this.idc);
    }
  };


  zoom = (dz) => {
    camera.zoom(dz)
  };

  pan = (dx, dy) => {
    camera.pan(dx, dy)
  };

  getPanFriction = () => {
    const cameraZoom =
      (camera.eye[2] - MinCameraDistance) /
      (MaxCameraDistance - MinCameraDistance);
    const closeFriction = 6000;
    const farawayFriction = 500;
    return lerp(closeFriction, farawayFriction, cameraZoom);
  };

  render() {
    return (
      <div style={{ position: "relative" }}>
        <canvas
          style={{
            background: "white",
            width: "100%",
            height: this.props.canvasHeight,
            cursor: this.props.mode === "pan" ? PanCursor : PaintCursor,
            userSelect: "none",
            WebkitUserSelect: "none",
          }}
          ref={this.canvasRef}
          // onTouchStart={(event) => {
          //   if (event.touches.length > 1) {
          //     console.log("preenting");
          //     event.preventDefault();
          //   }
          // }}
          onTouchStart={(e) => {
            this.isTouchScreen = true;
            document.body.style.webkitUserSelect = "none";
            if (e.touches.length === 1) {
              const rect = e.currentTarget.getBoundingClientRect();
              const x = e.targetTouches[0].pageX - rect.left;
              const y = e.targetTouches[0].pageY - rect.top;
              this.lastTouchX = x;
              this.lastTouchY = y;
              if (this.props.mode === "brush" || this.props.mode === "eraser") {
                this.paint(x, y);
              }
            }
          }}
          onTouchEnd={() => {
            // TODO: comment what this is for.
            document.body.style.webkitUserSelect = null as any;
          }}
          onTouchMove={(e) => {
            if (e.touches.length < 2) {
              const rect = e.currentTarget.getBoundingClientRect();
              const x = e.targetTouches[0].pageX - rect.left;
              const y = e.targetTouches[0].pageY - rect.top;

              // TODO: clean this up
              // TODO: ability to pan and zoom at the same time, like a pinch gesture everyone is used to.
              if (this.props.mode === "brush" || this.props.mode === "eraser") {
                this.paint(x, y);
              } else {
                const friction = this.getPanFriction();
                this.pan(
                  -(x - this.lastTouchX) / friction,
                  (y - this.lastTouchY) / friction
                );
                this.lastTouchX = x;
                this.lastTouchY = y;
              }
            }
          }}
          onMouseDown={(e) => {
            if (this.isTouchScreen) {
              return;
            }
            this.mousePressed = true;
            if (this.props.mode === "brush" || this.props.mode === "eraser") {
              this.paint(e.nativeEvent.offsetX, e.nativeEvent.offsetY);
            }
          }}
          onMouseUp={() => {
            if (this.isTouchScreen) {
              return;
            }
            this.mousePressed = false;
          }}
          onMouseMove={(e) => {
            if (this.isTouchScreen) {
              return;
            }
            if (this.props.mode === "brush" || this.props.mode === "eraser") {
              this.updateCursor(e.nativeEvent.offsetX, e.nativeEvent.offsetY);
            }

            if (this.mousePressed) {
              if (this.props.mode === "brush" || this.props.mode === "eraser") {
                this.paint(e.nativeEvent.offsetX, e.nativeEvent.offsetY);
              } else {
                const panFriction = this.getPanFriction();
                this.pan(-e.movementX / panFriction, e.movementY / panFriction);
              }
            }
          }}
        ></canvas>
      </div>
    );
  }
}
