import { vec3 } from "gl-matrix";

export enum SubscriptionStatus {
  NoSubscription,
  SubscriptionExpired,
  SubscriptionActive,
}

export interface BrushColor {
  id: string;
  number: number;
  colorValue: vec3;
}

type uuid = string;

export interface Painting {
  id: uuid;
  userId: uuid;
  templateId: uuid;
  title: string;
}

export interface PaintingMetadata {
  id: string;
  title: string;
  templateUrl: string;
  createdAtHumanFriendly: string;
}

export interface PaintingTemplate {
  id: uuid;
  title: string;
  category: string;
  previewUrl: string;
  templateUrl: string;
  thumbnailUrl: string;
  createdAt: string;
  // TODO: don't send all this metadata when users request painting templates in the gallery.
  colorsRaw: string;
  tags: string;
  photographer: string;
  difficulty: string;
  facetSetting: string;
  colorSetting: string;
  borderSetting: string;
  originalUrl: string;
}

export type PaintingTemplateUserFacing = Pick<
  PaintingTemplate,
  | "category"
  | "createdAt"
  | "difficulty"
  | "id"
  | "previewUrl"
  | "templateUrl"
  | "tags"
  | "title"
  | "thumbnailUrl"
>;

// api
export type ChunkBlob = {
  id: string;
  chunkIndex: number;
  data: Blob;
};

export interface PaintingRequest {
  paintingId: string;
}

export interface PaintingResponse {
  // The buffer structure is as follows:
  // 4 bytes for the manifest size
  // The manifest, which consists of:
  // - title
  // - templateUrl
  // - colorsUrl
  // - chunks definitions
  // chunks
  buffer: ArrayBuffer;
}

export interface PaintingsRequest {}
export interface PaintingsResponse {
  paintings: PaintingMetadata[];
}

export interface NewPaintingRequest {
  templateId: PaintingTemplate["id"];
}

export interface NewPaintingResponse {
  newPaintingId: Painting["id"];
}

// https://github.com/zachlite/3d-coloring-book/blob/master/app/src/save.ts
export interface SavePaintingRequest {
  paintingId: Painting["id"];
  buffer: ArrayBuffer;
}

export interface SavePaintingResponse {
  ok: boolean;
  message: string;
}

export interface PaintingTemplatesRequest {}
export interface PaintingTemplatesResponse {
  templates: PaintingTemplate[];
}

export interface AuthenticateRequest {
  email: string;
  password: string;
}

export interface AuthenticateResponse {
  message: string;
}

export interface PurchaseRequest {
  templateId: string;
}

export interface PurchaseResponse {
  checkoutSessionId: string;
}

export interface FulfillPurchaseRequest {
  checkoutSessionId: string;
}
export interface FulfillPurchaseResponse {
  message: string;
}

export interface DemoPaintingRequest {
  templateId: string;
}

export interface DemoPaintingResponse {
  templateUrl: string;
  colorsUrl: string;
}

interface SignOutResponse {
  message: string;
}

export interface UpdateTemplateRequest {
  template: PaintingTemplate;
}
export interface UpdateTemplateResponse {}

export interface AddTemplateRequest {
  template: PaintingTemplate;
}
export interface AddTemplateResponse {}

export interface SignUpRequest {
  email: string;
  password: string;
}

export interface SignUpResponse {
  message: "user_exists" | "bad_password" | "ok";
}

export function NewSignUpResponse(
  message: SignUpResponse["message"]
): SignUpResponse {
  return { message };
}

export interface CreateCheckoutSessionRequest {
  priceId: string;
  withTrial: boolean;
}

export interface CreateCheckoutSessionResponse {
  sessionUrl: string;
}

export interface CreateCustomerPortalSessionRequest {}

export interface CreateCustomerPortalSessionResponse {
  sessionUrl: string;
}

export interface SaveChunksRequest {
  data: ArrayBuffer;
}

export interface SaveChunksResponse {}

export interface SendActivityRequest {
  anonymousUserId: string,
  pathname: string;
  search: string;
}
export interface SendActivityResponse {}

export interface API {
  requestRefreshSession: (req: void) => Promise<void>;
  authenticate: (req: AuthenticateRequest) => Promise<AuthenticateResponse>;
  authenticateWithGoogle: (req: {
    credential: string;
  }) => Promise<{ isNewUser: boolean }>;
  signOut: () => Promise<SignOutResponse>;
  requestPurchase: (req: PurchaseRequest) => Promise<PurchaseResponse>;
  requestFulfillPurchase: (
    req: FulfillPurchaseRequest
  ) => Promise<FulfillPurchaseResponse>;
  // request for getting a user's saved paintings
  requestUserPainting: (req: PaintingRequest) => Promise<PaintingResponse>;
  requestDemoPainting: (
    req: DemoPaintingRequest
  ) => Promise<DemoPaintingResponse>;
  requestPaintings: (req: PaintingsRequest) => Promise<PaintingsResponse>;
  requestNewPainting: (req: NewPaintingRequest) => Promise<NewPaintingResponse>;
  requestSavePainting: (
    req: SavePaintingRequest
  ) => Promise<SavePaintingResponse>;
  requestTemplates: (
    req: PaintingTemplatesRequest
  ) => Promise<PaintingTemplatesResponse>;
  requestUpdateTemplate: (
    req: UpdateTemplateRequest
  ) => Promise<UpdateTemplateResponse>;
  requestAddTemplate: (req: AddTemplateRequest) => Promise<AddTemplateResponse>;
  requestSignUp: (req: SignUpRequest) => Promise<SignUpResponse>;
  requestCreateCheckoutSession: (
    req: CreateCheckoutSessionRequest
  ) => Promise<CreateCheckoutSessionResponse>;
  requestCreateCustomerPortalSession: (
    req: CreateCustomerPortalSessionRequest
  ) => Promise<CreateCustomerPortalSessionResponse>;
  requestSubscriptionStatus: () => Promise<{
    status: SubscriptionStatus;
    userId: string;
  }>;
  requestSendActivity: (req: SendActivityRequest) => Promise<SendActivityResponse>
}
