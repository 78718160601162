import React from "react";

interface BrushSizePickerProps {
  displayMode: "large" | "small";
  brushSize: number;
  setBrushSize: (size: number) => void;
}

const BrushSizePickerComponent: React.FunctionComponent<BrushSizePickerProps> =
  (props) => {
    return (
      <div className={props.displayMode === "large" ? "py-4" : "py-1"}>
        <div className="row g-0 justify-content-between">
          <div className="col-10">
            <input
              className="form-range"
              min={1}
              max={50}
              type="range"
              value={props.brushSize}
              onChange={(e) => {
                props.setBrushSize(parseInt(e.currentTarget.value));
              }}
            ></input>
          </div>

          <div className="col-2">
            <p className="text-center m-0">
              <small>
                <strong>{props.brushSize}px</strong>
              </small>
            </p>
          </div>
        </div>
      </div>
    );
  };

export default BrushSizePickerComponent;
