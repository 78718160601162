import { api } from "./api";

export enum TrialOptions {
  Trial,
  NoTrial
}

export const GiveTrial = true;
export const DoNotGiveTrial = false;

const TestPrice = "price_1LvrbNF1Rw0qwOCZe8zpEYdS";
// const Price_9_99_Month = "price_1MxemMF1Rw0qwOCZrTE3YSWr";
const Price_4_99_Month = "price_1N8LqVF1Rw0qwOCZ3i3O8DQa";
export const PriceString = "$4.99"

export function goToCheckout(opts: TrialOptions) {
  const isProduction = process.env.APP_URL?.includes("oasispbn.com") || false;
  const priceId = isProduction ? Price_4_99_Month : TestPrice;
  api.requestCreateCheckoutSession({ priceId, withTrial: opts === TrialOptions.Trial }).then((res) => {
    window.location.href = res.sessionUrl;
  });
}
