import React from "react";
import { Link } from "react-router-dom";
import { PaintingMetadata } from "../../common/interfaces";
import { api } from "../api";

/*
Loading Animation Experiment
@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

const loadingStyle = {
  background: "linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #eee 33%)",
  borderRadius: "5px",
  backgroundSize: "200% 100%",
  animation: "1.5s shine linear infinite",
};

{this.state.isLoading && [1,2,3,4,5,6].map(i => {
  return <div style={{width: "33%", padding: "20px", height: "300px", ...loadingStyle}}></div>
})} 
*/

interface MyPaintingsPageState {
  paintings: PaintingMetadata[];
  isLoading: boolean;
}

export class MyPaintingsPage extends React.Component<{}, MyPaintingsPageState> {
  state = {
    isLoading: true,
    paintings: [] as PaintingMetadata[],
  };

  componentDidMount(): void {
    api.requestPaintings({}).then((res) => {
      this.setState({ paintings: res.paintings, isLoading: false });
    });
  }

  render() {
    if (this.state.isLoading) {
      return (
        <div className="row py-5">
          {this.state.isLoading && <p className="my-5">Loading...</p>}
        </div>
      );
    }

    return (
      <div className="row py-5">
        {this.state.paintings.length === 0 && (
          <div style={{ textAlign: "center" }}>
            <img
              style={{ width: "300px" }}
              className="mb-5"
              src={require("../../../assets/art.svg")}
            ></img>
            <p className="lead mb-4">
              Head over to the gallery to start painting.
            </p>
            <Link to="/gallery">
              <button className="btn btn-primary">Go to gallery</button>
            </Link>
          </div>
        )}

        {this.state.paintings.map((painting, i) => (
          <div className="col-md-6 col-lg-3 p-3" key={i}>
            <p className="lead mb-1">
              <Link
                to={`/paint?id=${painting.id}`}
                className="link-secondary text-decoration-none"
              >
                {painting.title}
              </Link>
            </p>
            <p className="text-secondary">
              <small>Created on {painting.createdAtHumanFriendly}</small>
            </p>

            <Link to={`/paint?id=${painting.id}`}>
              <div
                style={{
                  position: "relative",
                  borderRadius: "8px",
                  overflow: "hidden",
                }}
              >
                <img
                  loading="lazy"
                  src={painting.templateUrl}
                  className="zoom"
                  style={{
                    width: "100%",
                    height: "300px",
                    objectFit: "cover",
                    borderRadius: "8px",
                    filter: "brightness(80%)",
                  }}
                ></img>
              </div>
            </Link>
          </div>
        ))}
      </div>
    );
  }
}
