export async function loadImage(src): Promise<HTMLImageElement> {
  return new Promise((resolve, reject) => {
    var image = new Image();
    image.onload = () => {
      resolve(image);
    };
    image.onerror = (e) => {
      reject(e)
    }
    image.src = src;
    image.crossOrigin = "anonymous";
  });
}
