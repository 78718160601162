import React from "react";
import { Link } from "react-router-dom";
import { PriceString, TrialOptions, goToCheckout } from "../checkout";
import { FancyTitle } from "./FancyTitle";

export const SignUpButton = () => (
  <>
    <Link to="/sign-up">
      <button className="btn btn-primary btn-lg">Sign Up</button>
    </Link>
  </>
);

export const SubscribeButton = () => (
  <button
    onClick={() => goToCheckout(TrialOptions.NoTrial)}
    className="btn btn-primary btn-lg"
  >
    Subscribe
  </button>
);


const features = [
  { title: "Auto save", description: "Never lose your work." },
  { title: "No Ads", description: "Chill out without distractions." },
  {
    title: "Works on all your devices",
    description: "Curl up on the couch, or paint on the go.",
  },

  {
    title: "Different levels of difficulty",
    description: "Your choice of easy, medium, or hard.",
  },
  {
    title: "Unique and high quality",
    description: "Hand-selected paintings that you won't find anywhere else.",
  },
  {
    title: "Updated monthly",
    description: "There's always something new to paint.",
  },
];

export function Features(props) {
  return (
    <>
      {features.map((feature, i) => (
        <Feature
          key={i}
          {...feature}
          variant={props.variant}
          fullDetails={props.fullDetails ?? true}
        />
      ))}
    </>
  );
}

function Feature(props) {
  return (
    <div className="col-lg-4 col-6 mb-4">
      <div className="d-flex align-items-baseline">
        <i
          className={`bi bi-check-circle  ${
            props.variant === "light" ? "text-light" : "text-success"
          }`}
        ></i>
        <div className="ms-3">
          <p
            className={
              `mb-1 ${props.variant === "light" ? "text-light " : "text-dark"}`
            }
          >
            <strong>{props.title}</strong>
          </p>
          <p
            className={
              props.variant === "light" ? "text-white-50 " : "text-secondary"
            }
          >
            {props.description}
            {/* {props.fullDetails ? props.description : null} */}
          </p>
        </div>
      </div>
    </div>
  );
}

export function Pricing(props: React.PropsWithChildren) {
  return (
    <div className="container py-5">
      <div className="row">
        <div className="col-md-6">
          <FancyTitle>Simple pricing</FancyTitle>
          <h2>
            <strong>Unlimited Paintings, One low price</strong>
          </h2>
          <p>If you're not satisfied, cancel anytime.</p>
        </div>
        <div className="col-md-6 text-end">
          <div className="d-flex justify-content-end align-items-baseline">
            <h2>
              <strong>{PriceString}</strong>
            </h2>
            <p className="text-secondary">/ mo</p>
          </div>
          {props.children}
        </div>
      </div>
      <hr className="my-5" />
      <div className="row my-5">
        <div className="col">
          <h4>What's Included:</h4>
        </div>
      </div>
      <div className="row">
        <Features />
      </div>
    </div>
  );
}
