import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  PaintingTemplateUserFacing,
  SubscriptionStatus,
} from "../../common/interfaces";
import { api } from "../api";
import WithRouter, { WithRouterProps } from "../../common/WithRouter";
import { PreviewModal } from "../components/PreviewModal";
import { UnlockedTemplates } from "../unlockedTemplates";
import { DifficultyBadge } from "../components/DifficultyBadge";
import { Modal } from "../components/Modal";
import { Pricing, SignUpButton, SubscribeButton } from "../components/Pricing";
import { TrialOptions, goToCheckout } from "../checkout";

interface GalleryPageProps {
  authenticated: boolean;
  subscriptionStatus: SubscriptionStatus;
}

// I don't think there is any
interface GalleryPageState {
  loading: boolean;
  templates: PaintingTemplateUserFacing[];
  categories: string[];
  coverPhotoUrl: string;
  showModal: boolean;
  selectedCategory: string;
  fuzzyFilter: string;
  sortOption: SortOptions;
  showLockedPaintings: boolean;
  showEasyDifficulty: boolean;
  showMediumDifficulty: boolean;
  showHardDifficulty: boolean;
}

enum SortOptions {
  Unlocked,
  Newest,
  Difficulty,
}

interface UnlockEverythingPromptProps {
  showLockedPaintings: boolean;
  authenticated: boolean;
  subscriptionStatus: SubscriptionStatus;
  setShowLockedPaintings: (boolean) => void;
}
function UnlockEverythingPrompt(props: UnlockEverythingPromptProps) {
  if (props.subscriptionStatus === SubscriptionStatus.SubscriptionActive) {
    return undefined;
  }

  return (
    <div className="col-md-6">
      <div className="alert alert-info py-4 row g-0 justify-content-between">
        <div className="col-lg-6 mb-2">
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckDefault"
              checked={props.showLockedPaintings}
              onChange={(e) => {
                props.setShowLockedPaintings(e.currentTarget.checked);
              }}
            />
            <label
              className="form-check-label"
              htmlFor="flexSwitchCheckDefault"
            >
              Show locked paintings
            </label>
          </div>
        </div>
        <div className="col-lg-6">
          {props.authenticated ? (
            <button
              onClick={() => {
                goToCheckout(TrialOptions.NoTrial);
              }}
              className="btn btn-sm btn-primary w-100"
            >
              ✨ Unlock Everything
            </button>
          ) : (
            <Link to="/sign-up">
              <button className="btn btn-sm btn-primary w-100">
                ✨ Unlock Everything
              </button>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}

// Concerns:
// How does someone know to click the painting card and see a preview?
// I must sort the cards such that the 10 unlocked ones are always first.
// Maybe I should have two sections: "Paint these now" and "Subscribe to unlock"
// There would be no need to paginate the first section, because its just 10

interface PaintingCardProps extends React.PropsWithChildren {
  template: PaintingTemplateUserFacing;
  handleClick: (templateId: string) => void;
}

const PaintingCard: React.FunctionComponent<PaintingCardProps> = (props) => {
  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    document.body.style.cursor = hovered ? "pointer" : "auto";
  }, [hovered]);

  return (
    <div
      style={{
        position: "relative",
        borderRadius: "8px",
        overflow: "hidden",
      }}
      onPointerOver={() => setHovered(true)}
      onPointerOut={() => setHovered(false)}
      onClick={() => props.handleClick(props.template.id)}
    >
      <div
        style={{
          position: "absolute",
          zIndex: 10,
          left: 0,
          right: 0,
          padding: "20px",
          background:
            "linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0,0,0,.5)",
        }}
      >
        <strong>
          <p className="text-light">{props.template.title}</p>
        </strong>
        <DifficultyBadge difficulty={props.template.difficulty} />
      </div>

      <img
        loading="lazy"
        src={props.template.thumbnailUrl}
        style={{
          width: "100%",
          height: "300px",
          objectFit: "cover",
          // borderRadius: "8px",
          filter: "brightness(80%)",
          transition: "all 0.2s linear",
          transform: hovered ? "scale(1.1)" : "none",
        }}
      ></img>

      {/* button wrapper */}
      <div
        style={{
          position: "absolute",
          right: "0",
          left: "0",
          bottom: "0",
          padding: "20px",
          background:
            "linear-gradient(to top, rgba(0, 0, 0, .5), rgba(0,0,0,0)",
          zIndex: 11,
        }}
      >
        {props.children}
      </div>
    </div>
  );
};

function difficultyValue(difficulty) {
  if (difficulty === "Easy") {
    return 1;
  }
  if (difficulty === "Medium") {
    return 2;
  }

  // Hard
  return 3;
}

function CategoryPill(props) {
  let className = "text-white p-3 me-2 category-pill";
  if (props.isActive) {
    className += " category-pill-active";
  }
  return (
    <p
      className={className}
      style={{
        borderRadius: "32px",
        textAlign: "center",
        minWidth: "80px",
        backgroundColor: "rgba(0, 0, 0, .5)",
      }}
      onClick={(e) => props.setCategory(props.category)}
    >
      <small>{props.category}</small>
    </p>
  );
}

function NoResults(props) {
  return (
    <div className="col-12">
      <div className="card p-5" style={{ textAlign: "center" }}>
        <p className="lead">Sorry, no results found.</p>
        <p className="small">
          Please try again with different keywords or broaden your search
          criteria.
        </p>
      </div>
    </div>
  );
}

class GalleryPage extends React.Component<
  GalleryPageProps & WithRouterProps,
  GalleryPageState
> {
  state = {
    sortOption: SortOptions.Unlocked,
    templates: [] as PaintingTemplateUserFacing[],
    categories: [] as string[],
    selectedCategory: "All",
    fuzzyFilter: "",
    showModal: false,
    previewTemplate: {} as PaintingTemplateUserFacing,
    coverPhotoUrl: "",
    showLockedPaintings: true,
    showEasyDifficulty: true,
    showMediumDifficulty: true,
    showHardDifficulty: true,
    loading: true,
  };

  componentDidMount() {
    api.requestTemplates({ allMetadata: false }).then((res) => {
      // find unique categories
      const categories = Array.from(
        new Set(res.templates.map((t) => t.category)).keys()
      );

      // choose a random template to set as the cover photo
      const random = Math.floor(Math.random() * res.templates.length);
      const coverPhotoUrl = res.templates[random].previewUrl;

      this.setState({
        templates: res.templates,
        categories,
        coverPhotoUrl,
        loading: false,
      });
    });
  }

  handlePaintIntent = (templateId: PaintingTemplateUserFacing["id"]) => {
    console.log("paint intent: ", templateId);
    const unlocked = UnlockedTemplates.has(templateId);

    // Unauthenticated:
    if (!this.props.authenticated) {
      if (unlocked) {
        this.props.router.navigate(`/paint?tid=${templateId}`);
        return;
      }

      // locked:
      // show modal that prompts user to sign up
      this.setState({ showModal: true });
      return;
    }

    // Authenticated:
    if (
      this.props.subscriptionStatus === SubscriptionStatus.SubscriptionActive
    ) {
      api
        .requestNewPainting({ templateId })
        .then((res) =>
          this.props.router.navigate(`/paint?id=${res.newPaintingId}`)
        );
      return;
    }

    if (unlocked) {
      this.props.router.navigate(`/paint?tid=${templateId}`);
      return;
    }

    // TODO: for development purposes, I should be able to enable and disable my subscription
    // easily
    this.setState({ showModal: true });
    // locked and not subscribed:
    // show modal that prompts user to subscribe.
  };

  // TODO: Record user category and filter preferences!
  handleCategorySelect = (e) => {
    const selected = e.target.value;
    this.setState({ selectedCategory: selected });
  };

  handleFuzzyFilter = (e) => {
    this.setState({ fuzzyFilter: e.target.value });
  };

  componentWillUnmount(): void {
    document.body.style.cursor = "auto";
  }

  render() {
    const { templates, selectedCategory, fuzzyFilter } = this.state;

    // Filter templates that match the selected category AND the fuzzy filter.
    const lowerCaseFilter = fuzzyFilter.toLowerCase();
    const templatesMatchingFilter = templates.filter((t) => {
      return (
        t.tags.toLowerCase().includes(lowerCaseFilter) ||
        t.title.toLowerCase().includes(lowerCaseFilter)
      );
    });
    const matchingTemplates = (
      selectedCategory !== "All"
        ? templatesMatchingFilter.filter((t) => t.category === selectedCategory)
        : templatesMatchingFilter
    )
      .filter((t) => {
        if (this.state.showLockedPaintings) {
          return true;
        }
        return UnlockedTemplates.has(t.id);
      })
      .filter((t) => {
        if (!this.state.showEasyDifficulty && t.difficulty === "Easy") {
          return false;
        }
        if (!this.state.showMediumDifficulty && t.difficulty === "Medium") {
          return false;
        }
        if (!this.state.showHardDifficulty && t.difficulty === "Hard") {
          return false;
        }
        return true;
      });

    // Sort by date added or difficulty
    if (this.state.sortOption === SortOptions.Unlocked) {
      matchingTemplates.sort((a, b) => {
        const aVal = UnlockedTemplates.has(a.id) ? 1 : 0;
        const bVal = UnlockedTemplates.has(b.id) ? 1 : 0;
        return bVal - aVal;
      });
    } else if (this.state.sortOption === SortOptions.Newest) {
      matchingTemplates.sort((a, b) => {
        return (
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );
      });
    } else {
      matchingTemplates.sort((a, b) => {
        const aDiff = difficultyValue(a.difficulty);
        const bDiff = difficultyValue(b.difficulty);
        return aDiff - bDiff;
      });
    }

    return (
      <div>
        <div
          style={{
            position: "relative",
            width: "100%",
            height: "500px",
            background: "#eee",
          }}
        >
          <div
            className="container-fluid p-5"
            style={{ position: "absolute", zIndex: 1000, top: "5vh" }}
          >
            <div className="row">
              <div className="col">
                <div className="d-flex flex-wrap">
                  <CategoryPill
                    category={"All"}
                    isActive={this.state.selectedCategory === "All"}
                    setCategory={(category) =>
                      this.setState({ selectedCategory: category })
                    }
                  />
                  {this.state.categories.map((category, i) => {
                    return (
                      <CategoryPill
                        key={i}
                        category={category}
                        isActive={this.state.selectedCategory === category}
                        setCategory={(category) =>
                          this.setState({ selectedCategory: category })
                        }
                      />
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 mt-4">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search..."
                  value={fuzzyFilter}
                  onChange={(e) => this.handleFuzzyFilter(e)}
                ></input>
              </div>
            </div>
          </div>

          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "500px",
              background: "rgba(0, 0, 0, .5)",
            }}
          ></div>

          {this.state.coverPhotoUrl && (
            <img
              src={this.state.coverPhotoUrl}
              style={{
                width: "100%",
                height: "500px",
                objectFit: "cover",
              }}
            ></img>
          )}
        </div>

        <div className="container-fluid p-5">
          <Modal
            open={this.state.showModal}
            close={() => {
              this.setState({ showModal: false });
            }}
          >
            <Pricing>
              {!this.props.authenticated ? (
                <SignUpButton />
              ) : (
                <SubscribeButton />
              )}
            </Pricing>
          </Modal>

          {this.state.loading ? (
            <>
              <p className="placeholder-wave">
                <span className="placeholder w-75"></span>
              </p>
              <p className="placeholder-wave">
                <span className="placeholder w-50"></span>
              </p>
              <p className="placeholder-wave">
                <span className="placeholder w-25"></span>
              </p>
            </>
          ) : (
            <>
              <div className="row py-4 justify-content-between">
                {/* sort / filter settings */}
                <div className="col-md-6">
                  {/* sort by */}
                  <div className="mb-4">
                    <p className="small">Sort By</p>
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      value={this.state.sortOption}
                      onChange={(e) => {
                        this.setState({
                          sortOption: parseInt(e.currentTarget.value),
                        });
                      }}
                    >
                      {this.props.subscriptionStatus !==
                        SubscriptionStatus.SubscriptionActive && (
                        <option value={SortOptions.Unlocked}>Unlocked</option>
                      )}
                      <option value={SortOptions.Newest}>Newest</option>
                      <option value={SortOptions.Difficulty}>Difficulty</option>
                    </select>
                  </div>

                  {/* difficulty */}
                  <div className="mb-4">
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="check-easy"
                        checked={this.state.showEasyDifficulty}
                        onChange={(e) => {
                          if (
                            !this.state.showMediumDifficulty &&
                            !this.state.showHardDifficulty
                          ) {
                            return;
                          }
                          this.setState({
                            showEasyDifficulty: e.currentTarget.checked,
                          });
                        }}
                      />
                      <label className="form-check-label" htmlFor="check-easy">
                        Easy
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="check-medium"
                        checked={this.state.showMediumDifficulty}
                        onChange={(e) => {
                          if (
                            !this.state.showEasyDifficulty &&
                            !this.state.showHardDifficulty
                          ) {
                            return;
                          }
                          this.setState({
                            showMediumDifficulty: e.currentTarget.checked,
                          });
                        }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="check-medium"
                      >
                        Medium
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="check-hard"
                        checked={this.state.showHardDifficulty}
                        onChange={(e) => {
                          if (
                            !this.state.showEasyDifficulty &&
                            !this.state.showMediumDifficulty
                          ) {
                            return;
                          }
                          this.setState({
                            showHardDifficulty: e.currentTarget.checked,
                          });
                        }}
                      />
                      <label className="form-check-label" htmlFor="check-hard">
                        Hard
                      </label>
                    </div>
                  </div>
                </div>

                {/* unlock alert */}
                <UnlockEverythingPrompt
                  showLockedPaintings={this.state.showLockedPaintings}
                  authenticated={this.props.authenticated}
                  subscriptionStatus={this.props.subscriptionStatus}
                  setShowLockedPaintings={(show) =>
                    this.setState({ showLockedPaintings: show })
                  }
                />
              </div>

              <div className="row">
                {matchingTemplates.length > 0 ? (
                  matchingTemplates.map((template, i) => {
                    const isUnlocked =
                      this.props.subscriptionStatus ===
                        SubscriptionStatus.SubscriptionActive ||
                      UnlockedTemplates.has(template.id);

                    return (
                      <div className="col-md-6 col-lg-3 p-3" key={i}>
                        <PaintingCard
                          key={template.id}
                          template={template}
                          handleClick={this.handlePaintIntent}
                        >
                          {!isUnlocked && (
                            <span className="text-light fs-2">
                              <i className="bi bi-lock-fill"></i>
                            </span>
                          )}
                        </PaintingCard>
                      </div>
                    );
                  })
                ) : (
                  <NoResults />
                )}
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}

export default WithRouter<GalleryPageProps>(GalleryPage);

// - what is their subscription tier? -> easy
// - how many unlocks have they made since the start of the month? ->
// - when do their unlocks refresh?  the first of each month ->

// total unlocks available must account for rollover each month
// unlocks = unlocks/month * months of subscription - unlocks
// Do i care about subscription changes right now? no.

// no cron needed.
// maybe I should just use a cron

// but the benefit of maintaining a static count of the number of unlocks remaining
// is that if there's a customer problem, I can go in and manually set the number of customer's unlocks.
