import React from "react";

export function FancyTitle(props) {
  let className = "fancy";
  if (props.variant === "light") {
    className += " text-light";
  } else {
    className += " text-primary";
  }
  return <h5 className={className}>{props.children}</h5>;
}
