import React from "react";
import { Link } from "react-router-dom";
import { api } from "../api";
import WithRouter, { WithRouterProps } from "../../common/WithRouter";
import { ContinueWithGoogleSection } from "../components/googleAuth";
import { signinSuccess } from "../signinSuccess";

interface SignInPageProps {
  setAuthenticated: () => void;
}

class SignInPage extends React.Component<WithRouterProps & SignInPageProps> {
  state = { password: "", email: "", loading: false };

  doSignIn = () => {
    if (!this.state.password.trim() || !this.state.email.trim()) {
      alert("Please enter your email and password.");
      return;
    }

    this.setState({ loading: true });

    setTimeout(() => {
      api
        .authenticate({
          email: this.state.email,
          password: this.state.password,
        })
        .then((res) => {
          this.props.setAuthenticated();
          signinSuccess(this.props.router);
        })
        .catch((authError) => {
          // TODO: handle auth error
          console.error("auth error: ", authError);
          alert("Please check your credentials and try again.");
        })
        .finally(() => {
          this.setState({ loading: false });
        });
    }, 1000);
  };

  render() {
    const formValid = this.state.email && this.state.password;
    return (
      <div className="container text-center my-5"  style={{minHeight: "700px"}}>
        <div className="row justify-content-center mb-4">
          <div className="col-md-6">
            <h2>
              <strong>Welcome back!</strong>
            </h2>
            <p>
              Or <Link to="/sign-up">Sign up.</Link>
            </p>
          </div>
        </div>

        <form
          onSubmit={(e) => {
            e.preventDefault();
            this.doSignIn();
          }}
        >
          <div className="row justify-content-center">
            <div className="col-md-6">
              <div className="form-floating mb-3">
                <input
                  type="email"
                  className="form-control form-control-sm"
                  id="floatingInput"
                  placeholder="name@example.com"
                  onChange={(e) =>
                    this.setState({ email: e.currentTarget.value })
                  }
                />
                <label htmlFor="floatingInput">Email</label>
              </div>
              <div className="form-floating">
                <input
                  type="password"
                  className="form-control form-control-sm"
                  id="floatingPassword"
                  placeholder="Password"
                  onChange={(e) =>
                    this.setState({ password: e.currentTarget.value })
                  }
                />
                <label htmlFor="floatingPassword">Password</label>
              </div>
            </div>
          </div>

          <div className="row justify-content-center mt-3">
            <div className="col-md-6">
              <button
                type="submit"
                disabled={!formValid}
                className="btn btn-primary btn-lg w-100"
              >
                {this.state.loading ? (
                  <div className="d-flex justify-content-center">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <>
                    <span
                      className="text-light"
                      style={{
                        float: "left",
                        marginTop: "3px",
                      }}
                    >
                      <i className="bi bi-lock-fill"></i>
                    </span>
                    <span style={{ marginLeft: "-20px" }}>Sign in</span>
                  </>
                )}
              </button>
            </div>
          </div>
        </form>
        <ContinueWithGoogleSection
          colClass="col-md-6"
          text={"signin_with"}
          router={this.props.router}
          setAuthenticated={this.props.setAuthenticated}
        />
      </div>
    );
  }
}

export default WithRouter<SignInPageProps>(SignInPage);
