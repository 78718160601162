// import * as Sentry from "@sentry/browser";
import React from "react";
import debounce from "lodash/debounce";
import {
  BrushColor,
  ChunkBlob,
  PaintingResponse,
  SubscriptionStatus,
} from "../../common/interfaces";
import { api } from "../api";
import BrushColorPickerComponent from "../components/BrushColorPicker";
import BrushSizePickerComponent from "../components/BrushSizePicker";
import { PaintCanvas, PaintCanvasProps } from "../components/PaintCanvas";
import WithRouter, { WithRouterProps } from "../../common/WithRouter";
import {
  WithWindowDimension,
  useWindowDimensions,
} from "../components/useWindowDimensions";
import { ImageDataContainer } from "../imageDataContainer";
import { CanvasMode, ModeButtons } from "../components/PaintingModeButtons";
import { Link } from "react-router-dom";
import { UnlockedTemplates } from "../unlockedTemplates";
import { TrialOptions, goToCheckout } from "../checkout";

function PaintingNotFound() {
  return (
    <div className="container my-5">
      <h1>Hmm... that's not right.</h1>
      <p>Check the URL or try again later.</p>
    </div>
  );
}

interface PaintingPageBannerProps {
  enabled: boolean;
  authenticated: boolean;
  subscription: SubscriptionStatus;
}
export function PaintingPageBanner(props: PaintingPageBannerProps) {
  if (props.subscription === SubscriptionStatus.SubscriptionActive) {
    return null;
  }

  let title;
  let body;
  const cta = !props.authenticated ? (
    <Link to="/sign-up">
      <button className="btn btn-sm btn-primary">Subscribe</button>
    </Link>
  ) : (
    <button
      onClick={() => {
        goToCheckout(TrialOptions.NoTrial);
      }}
      className="btn btn-sm btn-primary"
    >
      Subscribe
    </button>
  );

  if (props.enabled) {
    title = "Pssst...This is just a demo! 🎨";
    body = "Subscribe to unlock every painting and save your work.";
  } else {
    title = "Subscribe to unlock this painting! 🖌️🎨";
    body = "Unlock every painting and save your work.";
    // TODO: Make sure there's no fall through case
  }

  return (
    <div className="alert alert-primary m-0">
      <div className="row align-items-center">
        <div className="col-8">
          <p className="small mb-2">
            <strong>{title}</strong>
          </p>
          <p className="small mb-0">{body}</p>
        </div>
        <div className="col-4 text-end">{cta}</div>
      </div>
    </div>
  );
}

interface PaintingPageProps {
  authenticated: boolean;
  subscription: SubscriptionStatus;
}
interface PaintingPageState {
  title: string;
  blobs: ChunkBlob[];
  templateUrl: string;
  brushColors: BrushColor[];
  brushSize: number;
  brushColor: BrushColor;
  mode: CanvasMode;
  saving: boolean;
  notFound: boolean;
  loading: boolean;
  magicallyFinished: boolean;
  previewUrl: string;
  canvasHeightCss: string;
}

const DefaultBrushColor: BrushColor = {
  id: "xxx-xxx-xxx-xxxx",
  number: 0,
  colorValue: [244, 135, 111],
};

interface SavingIndicatorComponentProps {
  saveEnabled: boolean;
  saving: boolean;
}

function SavingIndicatorComponent(props: SavingIndicatorComponentProps) {
  const { saveEnabled, saving } = props;

  if (!saveEnabled) {
    return undefined;
  }

  if (saving) {
    return (
      <div className="alert alert-primary">
        <div className="d-flex align-items-center justify-content-start">
          <span
            className="spinner-border spinner-border-sm mx-2"
            role="status"
            aria-hidden="true"
          ></span>
          <p className="m-0">
            <small>Saving...</small>
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="alert alert-success">
      <div className="d-flex align-items-center justify-content-start">
        <p className="m-0">
          <i className="me-2 bi bi-check-circle-fill"></i>
          <small>Saved.</small>
        </p>
      </div>
    </div>
  );
}

function DisabledCover(props) {
  return (
    !props.enabled && (
      <div
        style={{
          width: "100%",
          height: "calc(100%)",
          background: "rgba(0,0,0,.7)",
          position: "absolute",
          zIndex: "10000",
        }}
      ></div>
    )
  );
}

class PaintingPage extends React.Component<
  PaintingPageProps &
    WithRouterProps & { windowDim: { width: number; height: number } },
  PaintingPageState
> {
  saveDebounced = () => {};
  brushSettingsContainerRef: React.RefObject<HTMLDivElement>;
  modeButtonsContainerRef: React.RefObject<HTMLDivElement>;
  constructor(props) {
    super(props);
    this.brushSettingsContainerRef = React.createRef();
    this.modeButtonsContainerRef = React.createRef();
    this.state = {
      notFound: false,
      title: "",
      blobs: [],
      templateUrl: "",
      brushColors: [],
      saving: false,
      brushSize: 25,
      brushColor: DefaultBrushColor,
      mode: "pan",
      loading: true,
      magicallyFinished: false,
      previewUrl: "",
      canvasHeightCss: "100%",
    };
  }

  toggleScroll = () => {
    const root = document.getRootNode() as any;
    root.body.classList.toggle("noscroll");
  };

  componentWillUnmount(): void {
    this.toggleScroll();
  }

  save = (idc: ImageDataContainer, paintingId: string) => {
    const saveStartTime = window.performance.now();
    this.setState({ saving: true });

    idc.getBlobsForDirtyChunks().then((blobs) => {
      const totalBlobSize = blobs.reduce((acc, curr) => {
        return acc + curr.buffer.byteLength;
      }, 0);

      const manifest = blobs.map((blob) => {
        return {
          id: blob.id,
          index: blob.chunkIndex,
          byteLength: blob.buffer.byteLength,
        };
      });

      // serialize the manifest
      let te = new TextEncoder();
      const binaryManifest = te.encode(JSON.stringify(manifest));
      console.log("binary manifest size: ", binaryManifest.byteLength);

      // Allocate a buffer
      const buffer = new Uint8Array(
        4 + binaryManifest.byteLength + totalBlobSize
      );

      // Write the size of the manifest to the buffer
      const manifestSizeBuffer = new Uint8Array(4);
      const dv = new DataView(manifestSizeBuffer.buffer);
      dv.setInt32(0, binaryManifest.byteLength, true); // true for little-endian.
      buffer.set(manifestSizeBuffer, 0);

      // Write the manifest to the buffer
      buffer.set(binaryManifest, 4);

      // Write the blobs to the buffer
      // But i can't do that with a blob? I need to convert the blob to a uint8array first?
      let offset = 4 + binaryManifest.byteLength;
      for (const blob of blobs) {
        buffer.set(blob.buffer, offset);
        offset += blob.buffer.byteLength;
      }

      // send that MF!
      api
        .requestSavePainting({ paintingId: paintingId, buffer })
        .then(() => {
          const saveEndTime = window.performance.now();
          console.log("saved in ms: ", saveEndTime - saveStartTime);
          const waitTime = Math.max(500 - (saveEndTime - saveStartTime), 0);
          setTimeout(() => {
            this.setState({ saving: false });
          }, waitTime);

          for (let blob of blobs) {
            idc.setChunkClean(blob.chunkIndex);
          }
        })
        .catch((err) => {
          const sentryError = new Error("Save painting failed.", {
            cause: err,
          });
          // Sentry.captureException(sentryError);
          console.warn("could not save: ", sentryError);
        });
    });
  };

  loadUserPainting = (userPaintingId: string) => {
    // Because we are loading a user painting, the id is a user's painting id.
    this.setState({
      magicallyFinished:
        this.props.router.searchParams.get("magic") === "true" ? true : false,
    });

    // Init save function
    this.saveDebounced = debounce(
      (idc: ImageDataContainer) => this.save(idc, userPaintingId),
      1000 // allow at most 1 save per second.
    );

    // Start loading painting data
    this.setState({ loading: true });
    const paintingResponse = api.requestUserPainting({
      paintingId: userPaintingId!,
    });

    return paintingResponse
      .then(async (res) => {
        /// do stuff with the array buffer!
        const bufferArray = new Uint8Array(res.buffer);

        // Get the manifest size:
        const manifestSize = new DataView(res.buffer).getUint32(0, true); // Little endian.

        // Parse the manifest.
        const td = new TextDecoder();
        const rawManifest = td.decode(
          bufferArray.subarray(4, 4 + manifestSize)
        );
        const manifest = JSON.parse(rawManifest);
        // Convert the chunks into blobs

        let offset = 4 + manifestSize;
        const chunkBlobs = [] as ChunkBlob[];
        for (const chunk of manifest.chunks) {
          const chunkBlob = {
            id: chunk.id,
            chunkIndex: chunk.index,
            data: new Blob(
              [res.buffer.slice(offset, offset + chunk.byteLength)],
              { type: "image/png" }
            ),
          };

          chunkBlobs.push(chunkBlob);
          offset += chunk.byteLength;
        }

        const colors = await fetch(manifest.brushColors)
          .then((res) => res.json())
          .then((colors) => {
            return colors.map((c, i) => {
              return { id: i, number: i, colorValue: c.color };
            });
          });
        this.setState({
          title: manifest.title,
          templateUrl: manifest.templateUrl,
          previewUrl: manifest.previewUrl,
          brushColors: colors,
          brushColor: colors[0] as BrushColor,
          blobs: chunkBlobs,
        });
      })
      .catch((e) => {
        console.error(e);
        this.setState({ notFound: true });
        // user could be requesting their own painting when logged out
        // user could be requesting a painting that is not theres (unlikely)
        // user could be requesting a painting that does not exist.
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  loadDemo = (templateId: string) => {
    // Demo Mode.
    this.setState({ loading: true });
    // Fetch the template url and the brush color url
    return api
      .requestDemoPainting({ templateId })
      .then((res) => {
        this.setState({ templateUrl: res.templateUrl });
        fetch(res.colorsUrl)
          .then((res) => res.json())
          .then((colors) => {
            return colors.map((c, i) => {
              return { id: i, number: i, colorValue: c.color };
            });
          })
          .then((colors) => {
            this.setState({
              brushColors: colors,
              brushColor: colors[0] as BrushColor,
              loading: false,
            });
          });
      })
      .catch((e) => {
        console.error(e);
        this.setState({ notFound: true });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  componentDidMount() {
    this.toggleScroll();

    // New rule: if the user doesn't have a subsciption, nothing gets saved.
    const userPaintingId = this.props.router.searchParams.get("id");
    const templateId = this.props.router.searchParams.get("tid");

    if (!userPaintingId && !templateId) {
      this.setState({ notFound: true });
      return;
    }

    let res;

    // Does the user have a session?
    if (userPaintingId) {
      res = this.loadUserPainting(userPaintingId);
    } else {
      res = this.loadDemo(templateId);
    }

    res.then(() => {
      window.scrollTo(0, 0);
    });
  }

  componentDidUpdate() {
    let canvasHeightCss = "50%";
    if (
      this.brushSettingsContainerRef.current &&
      this.modeButtonsContainerRef.current
    ) {
      const modeButtonsRect =
        this.modeButtonsContainerRef.current.getBoundingClientRect();
      const canvasTop = modeButtonsRect.top + modeButtonsRect.height;
      const canvasHeight =
        this.props.windowDim.height -
        canvasTop -
        this.brushSettingsContainerRef.current.getBoundingClientRect().height;
      canvasHeightCss = `${canvasHeight}px`;
    }

    if (this.state.canvasHeightCss !== canvasHeightCss) {
      this.setState({ canvasHeightCss });
    }
  }

  render() {
    if (this.state.notFound) {
      return <PaintingNotFound />;
    }

    if (this.state.loading) {
      return (
        <div style={{ minHeight: "1000px" }}>
          <div className="d-flex justify-content-around mt-5">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      );
    }

    const saveEnabled =
      this.props.subscription === SubscriptionStatus.SubscriptionActive &&
      this.props.router.searchParams.get("id") != null;

    const enabled =
      this.props.subscription == SubscriptionStatus.SubscriptionActive ||
      UnlockedTemplates.has(this.props.router.searchParams.get("tid"));

    // When the page width < 768, change to mobile layout
    const displayMode = this.props.windowDim.width > 768 ? "large" : "small";

    return (
      <>
        <PaintingPageBanner
          enabled={enabled}
          authenticated={this.props.authenticated}
          subscription={this.props.subscription}
        />

        {displayMode === "large" && (
          <div style={{ display: "flex", height: "calc(100% - 48px)" }}>
            <div
              style={{
                width: "30%",
                padding: "20px",
                position: "relative",
                boxShadow: "2px 0px 10px #00000020",
                zIndex: 10,
              }}
            >
              <SavingIndicatorComponent
                saveEnabled={saveEnabled}
                saving={this.state.saving}
              />

              <ModeButtons
                displayMode={displayMode}
                mode={this.state.mode}
                setMode={(mode) => this.setState({ mode })}
              />

              <div style={{ opacity: this.state.mode === "pan" ? ".2" : "1" }}>
                <div className="my-3">
                  <BrushSizePickerComponent
                    displayMode={displayMode}
                    brushSize={this.state.brushSize}
                    setBrushSize={(brushSize) => this.setState({ brushSize })}
                  />
                </div>

                <BrushColorPickerComponent
                  displayMode={displayMode}
                  colors={this.state.brushColors}
                  activeColor={
                    this.state.mode !== "brush" ? "" : this.state.brushColor.id
                  }
                  setBrushColor={(color: BrushColor["id"]) => {
                    const brushColor =
                      this.state.brushColors.find((bc) => bc.id === color) ||
                      DefaultBrushColor;
                    this.setState({ brushColor, mode: "brush" });
                  }}
                />
              </div>
            </div>
            <div style={{ width: "70%" }}>
              <PaintCanvas
                canvasHeight="100%"
                enabled={enabled}
                save={this.saveDebounced}
                brushSize={this.state.brushSize}
                brushColor={this.state.brushColor}
                mode={this.state.mode}
                blobs={this.state.blobs}
                templateUrl={this.state.templateUrl}
                magicallyFinished={this.state.magicallyFinished}
                previewUrl={this.state.previewUrl}
              />
            </div>

            <DisabledCover enabled={enabled} />
          </div>
        )}

        {displayMode === "small" && (
          <div style={{ position: "relative", zIndex: 10 }}>
            <DisabledCover enabled={enabled} />

            <SavingIndicatorComponent
              saveEnabled={saveEnabled}
              saving={this.state.saving}
            />

            <div
              style={{
                padding: "10px",
                boxShadow: "0px 2px 10px #00000020",
                zIndex: 10,
                position: "relative",
              }}
              ref={this.modeButtonsContainerRef}
            >
              <ModeButtons
                displayMode={displayMode}
                mode={this.state.mode}
                setMode={(mode) => this.setState({ mode })}
              />
            </div>

            <PaintCanvas
              canvasHeight={this.state.canvasHeightCss}
              enabled={enabled}
              save={this.saveDebounced}
              brushSize={this.state.brushSize}
              brushColor={this.state.brushColor}
              mode={this.state.mode}
              blobs={this.state.blobs}
              templateUrl={this.state.templateUrl}
              magicallyFinished={this.state.magicallyFinished}
              previewUrl={this.state.previewUrl}
            />

            {/* need height of this component */}
            <div
              style={{
                opacity: this.state.mode === "pan" ? ".2" : "1",
                boxShadow: "0px -2px 10px #00000020",
                zIndex: 10,
                position: "relative",
                padding: "10px",
              }}
              ref={this.brushSettingsContainerRef}
            >
              <BrushSizePickerComponent
                displayMode={displayMode}
                brushSize={this.state.brushSize}
                setBrushSize={(brushSize) => {
                  this.setState({ brushSize });
                }}
              />

              <BrushColorPickerComponent
                displayMode={displayMode}
                colors={this.state.brushColors}
                activeColor={
                  this.state.mode !== "brush" ? "" : this.state.brushColor.id
                }
                setBrushColor={(color: BrushColor["id"]) => {
                  const brushColor = this.state.brushColors.find(
                    (bc) => bc.id === color
                  );
                  this.setState({
                    brushColor: brushColor || DefaultBrushColor,
                    mode: "brush",
                  });
                }}
              />
            </div>
          </div>
        )}
      </>
    );
  }
}

export default WithWindowDimension(WithRouter<PaintingPageProps>(PaintingPage));
