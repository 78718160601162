// import * as Sentry from "@sentry/browser";
import { GoogleOAuthProvider } from "@react-oauth/google";
import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  Navigate,
  useLocation,
} from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { PaintingMetadata, SubscriptionStatus } from "../common/interfaces";
import { api } from "./api";
import { Footer } from "./components/Footer";
import { NavBar } from "./components/NavBar";
import { AboutPage } from "./pages/AboutPage";
import { AccountPage } from "./pages/AccountPage";
import GalleryPage from "./pages/GalleryPage";
import { MarketingPage } from "./pages/MarketingPage";
import { MyPaintingsPage } from "./pages/MyPaintingsPage";
import PaintingPage, { PaintingPageBanner } from "./pages/PaintingPage";
import SignInPage from "./pages/SignInPage";
import SignUpPage from "./pages/SignUpPage";
import SubscriptionSuccessPage from "./pages/SubscriptionSuccessPage";
import { shouldEnableTelemetry } from "../server/config";

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, [pathname]);

  return null;
}

export interface PaintingsListPageProps {
  paintings: PaintingMetadata[];
}

function NotFoundPage() {
  return (
    <div className="container my-5">
      <h1>Sorry, this page doesn't exist.</h1>
      <Link to="/">
        <button className="btn btn-secondary my-2">Home</button>
      </Link>
    </div>
  );
}

// If a user's subscription becomes invalid,
// everything becomes read-only
// 1. A site-wide banner is displayed saying they should subscribe
// 2. Clicks on "paint" redirect to /subscribe
// 3. The painting page is read-only

// There's also a difference between a user who has never subscribed
// and a user whose previously valid subscription is no longer valid

interface ApplicationState {
  authenticated: boolean;
  subscriptionStatus: SubscriptionStatus;
}

class App extends React.Component<{}, ApplicationState> {
  sessionRefreshInterval: NodeJS.Timer;
  activityInterval: NodeJS.Timer;
  constructor(props) {
    super(props);

    const authenticated = isAuthenticated();

    // if (shouldEnableTelemetry() && !authenticated) {
    //   this.activityInterval = initWatcher();
    // }

    this.state = {
      authenticated,
      subscriptionStatus: SubscriptionStatus.NoSubscription,
    };
  }

  signOut = () => {
    api.signOut().then(() => {
      this.setState({ authenticated: false }, () => {
        window.location.href = "/sign-in";
      });
    });
  };

  setAuthenticated = () => {
    // if (this.activityInterval) {
    //   clearInterval(this.activityInterval);
    // }
    this.setState({ authenticated: true });
    this.refreshSubscriptionStatus();
  };

  refreshSubscriptionStatus = () => {
    api.requestSubscriptionStatus().then((res) => {
      // Sentry.setUser({ id: res.userId });
      this.setState({ subscriptionStatus: res.status });
    });
  };

  maybeRefreshSession = () => {
    if (this.state.authenticated) {
      api.requestRefreshSession();
    }
  };

  componentDidMount() {
    const authenticated = isAuthenticated();
    this.setState({ authenticated });
    if (authenticated) {
      this.refreshSubscriptionStatus();
    }

    // Set up an interval to keep the user's session from expiring
    this.sessionRefreshInterval = setInterval(
      this.maybeRefreshSession,
      15 * 60 * 1000 // 15 minutes
    );
    this.maybeRefreshSession();
  }

  componentWillUnmount(): void {
    clearInterval(this.sessionRefreshInterval);
  }

  render() {
    return (
      <>
        <BrowserRouter>
          <ScrollToTop />
          {this.state.authenticated ? (
            <NavBar
              authenticated={true}
              subscriptionStatus={this.state.subscriptionStatus}
              signOut={this.signOut}
            />
          ) : (
            <NavBar authenticated={false} />
          )}

          <Routes>
            <Route path="/canceled" element={<Navigate to={"/"} />} />

            <Route
              path="/"
              element={
                this.state.authenticated ? (
                  <Navigate to="/gallery" />
                ) : (
                  <MarketingPage />
                )
              }
            />

            <Route
              path="/sign-in"
              element={
                this.state.authenticated ? (
                  <Navigate to={"/"} />
                ) : (
                  <SignInPage setAuthenticated={this.setAuthenticated} />
                )
              }
            />

            <Route
              path="/sign-up"
              element={
                this.state.authenticated ? (
                  <Navigate to={"/"} />
                ) : (
                  <SignUpPage setAuthenticated={this.setAuthenticated} />
                )
              }
            />

            <Route
              path="/success"
              element={
                this.state.authenticated ? (
                  <SubscriptionSuccessPage />
                ) : (
                  <NotFoundPage />
                )
              }
            />

            <Route
              path="/gallery/*"
              element={
                <GalleryPage
                  authenticated={this.state.authenticated}
                  subscriptionStatus={this.state.subscriptionStatus}
                />
              }
            />

            <Route
              path="/my-paintings"
              element={
                this.state.authenticated ? (
                  <div className="container">
                    <MyPaintingsPage />
                  </div>
                ) : (
                  <Navigate to="/sign-in?redirect=/my-paintings" />
                )
              }
            />

            <Route path="/about" element={<AboutPage />} />

            <Route
              path="/account"
              element={
                this.state.authenticated ? (
                  <AccountPage
                    subscriptionStatus={this.state.subscriptionStatus}
                  />
                ) : (
                  <Navigate to="/sign-in" />
                )
              }
            />

            <Route
              path="/paint"
              element={
                // this.state.subscriptionStatus ===
                //       SubscriptionStatus.NoSubscription && (
                //       <NoSubscriptionAlert />
                //     )

                <PaintingPage
                  authenticated={this.state.authenticated}
                  subscription={this.state.subscriptionStatus}
                />
              }
            />

            <Route
              path="/contact"
              element={
                <div
                  className="container my-5"
                  style={{ minHeight: "calc(60vh - 48px)" }}
                >
                  For questions or concerns, please email us at
                  support@oasispbn.com
                </div>
              }
            />

            <Route path="*" element={<NotFoundPage />} />
          </Routes>

          {!this.state.authenticated && <Footer />}
        </BrowserRouter>
      </>
    );
  }
}

function getOrCreateAnalyticsID(): string {
  const keyName = "anonymousUserId";
  let id = localStorage.getItem(keyName);
  if (id === null) {
    id = uuidv4();
    localStorage.setItem(keyName, id);
  }
  return id;
}

function initWatcher() {
  const interval = setInterval(() => {
    const { pathname, search } = window.location;
    const id = getOrCreateAnalyticsID();
    api
      .requestSendActivity({ anonymousUserId: id, pathname, search })
      .catch(() => {
        // noop
      });
  }, 1000);
  return interval;
}

function initTelemetry() {
  // Init sentry
  // There is a bug in the Sentry stack:
  // https://github.com/getsentry/sentry-javascript/issues/7941
  // Sentry.init({
  //   dsn: "https://9f0ac751e2c94d5dbf738508350c6638@o4504209500733440.ingest.sentry.io/4505072591634432",
  //   integrations: [new Sentry.BrowserTracing()],

  //   // Set tracesSampleRate to 1.0 to capture 100%
  //   // of transactions for performance monitoring.
  //   // We recommend adjusting this value in production
  //   tracesSampleRate: 1.0,
  // });

  // Init id that is shared across activity telemetry and google analytics.
  // Prioritizing logged out users.
  const id = getOrCreateAnalyticsID();

  // Init Google Auth
  const GAnalyticsScript = document.createElement("script");
  GAnalyticsScript.type = "text/javascript";
  GAnalyticsScript.async = true;
  GAnalyticsScript.onload = () => {
    (window as any).dataLayer = (window as any).dataLayer || [];
    function gtag() {
      (window as any).dataLayer.push(arguments);
    }
    (gtag as any)("js", new Date());
    (gtag as any)("config", "G-0X8HJ6XKZQ", { user_id: id });
  };
  GAnalyticsScript.src =
    "https://www.googletagmanager.com/gtag/js?id=G-0X8HJ6XKZQ";
  document.getElementsByTagName("head")[0].appendChild(GAnalyticsScript);
}

function boot() {
  const root = ReactDOM.createRoot(document.getElementById("root")!);
  root.render(
    <GoogleOAuthProvider clientId="786111031815-1jv3lsmf3gkohha8pf21sh4seadjglrv.apps.googleusercontent.com">
      <App />
    </GoogleOAuthProvider>
  );
}

function isAuthenticated() {
  const cookies = Object.fromEntries(
    document.cookie
      .split("; ")
      .map((v) => v.split(/=(.*)/s).map(decodeURIComponent))
  );

  if (cookies["has_access_token"] !== undefined) {
    return true;
  }

  return false;
}

window.onload = () => {
  if (shouldEnableTelemetry()) {
    initTelemetry();
  }
  boot();
};
