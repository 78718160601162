import React from "react";
import WithRouter, { WithRouterProps } from "../../common/WithRouter";

class SubscriptionSuccessPage extends React.Component<WithRouterProps> {
  state = { isLoading: true };

  componentDidMount = () => {
    // Poor man's synchronous event handling:
    // just wait enough time for the subscription to be entered.
    setTimeout(() => {
      this.props.router.navigate("/");
    }, 3000);
  };

  render() {
    return (
      <div className="container p-5">
        <h3>Awesome! 🌴🎨</h3>
        <p>
          Hang tight! We're setting things up for you...{" "}
          <span
            className="spinner-border spinner-border-sm mx-2"
            role="status"
            aria-hidden="true"
          ></span>
        </p>
      </div>
    );
  }
}

export default WithRouter(SubscriptionSuccessPage);
