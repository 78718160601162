import React from "react";
import { NavigateFunction, Params, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";

export interface WithRouterProps {
  router: {
    location: Location;
    navigate: NavigateFunction;
    params: Readonly<Params<string>>;
    searchParams: URLSearchParams
  };
}

export default function WithRouter<T>(Component) {
  function ComponentWithRouterProp(props: T) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    let [searchParams, _] = useSearchParams()
    return <Component {...props} router={{ location, navigate, params, searchParams }} />;
  }

  return ComponentWithRouterProp;
}