import { vec3 } from "gl-matrix";

export default function rayIntersect(origin, direction, rect) {
  const invDir = vec3.create();
  vec3.inverse(invDir, direction);
  const tx1 = (rect.minX - origin[0]) * invDir[0];
  const tx2 = (rect.maxX - origin[0]) * invDir[0];

  let tmin = Math.min(tx1, tx2);
  let tmax = Math.max(tx1, tx2);

  const ty1 = (rect.minY - origin[1]) * invDir[1];
  const ty2 = (rect.maxY - origin[1]) * invDir[1];

  tmin = Math.max(tmin, Math.min(ty1, ty2));
  tmax = Math.min(tmax, Math.max(ty1, ty2));

  const tz1 = (rect.minZ - origin[2]) * invDir[2];
  const tz2 = (rect.maxZ - origin[2]) * invDir[2];

  tmin = Math.max(tmin, Math.min(tz1, tz2));
  tmax = Math.min(tmax, Math.max(tz1, tz2));

  return {
    intersects: tmax >= Math.max(0, tmin),
    t: tmax,
  };
}