// ImageDataContainer contains the entire image buffer
// for what gets draw on screen. It knows about image chunks,
// and can return data for a specific chunk index.

import { ChunkBlob } from "../common/interfaces";
import { loadImage } from "./loadImage";
import { v4 as uuidv4 } from "uuid";

type Chunk = {
  id: string;
  dirty: boolean;
  index: number;
  xMin: number;
  yMin: number;
  width: number;
  height: number;
};

function pointInRect(x, y, xMin, yMin, width, height) {
  return x >= xMin && x <= xMin + width && y >= yMin && y <= yMin + height;
}

export function getImageData(image: HTMLImageElement): Uint8ClampedArray {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d")!;
  ctx.canvas.width = image.width;
  ctx.canvas.height = image.height;
  ctx.drawImage(image, 0, 0);
  return ctx.getImageData(0, 0, image.width, image.height).data;
}

async function getArrayBufferFromCanvas(canvas): Promise<Uint8Array> {
  const blob = (await new Promise((resolve) => {
    canvas.toBlob((blob) => resolve(blob), "image/png");
  })) as Blob;

  const array = await new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = () => {
      const arrayBuffer = reader.result as ArrayBuffer;
      resolve(new Uint8Array(arrayBuffer));
    };
    reader.readAsArrayBuffer(blob);
  });

  return array as Uint8Array;
}

// ImageDataContainer provides an abstraction over individual image chunks.
// Callers can access the underlying data of an entire image after constructing an ImageDataContainer
// from a collection of blobs. The ImageDataContainer maintains the dirty state of each chunk
// and can provide a blob for each dirty chunk.
export class ImageDataContainer {
  // The image's width in pixels
  private width: number;

  // The image's height in pixels
  private height: number;

  // The image's data.
  data: Uint8ClampedArray;

  // The chunks
  private chunks: Chunk[];

  constructor(width: number, height: number) {
    this.width = width;
    this.height = height;

    // Create the chunks.
    // Divide width and height by N.
    // Total chunks = N * N.
    this.chunks = this.initChunks(width, height, 16);
  }

  private initChunks(width: number, height: number, N: number) {
    const chunks = [];
    const standardChunkWidth = Math.ceil(width / N);
    const lastChunkWidth = width - (N - 1) * standardChunkWidth;

    const standardChunkHeight = Math.ceil(height / N);
    const lastChunkHeight = height - (N - 1) * standardChunkHeight;

    let index = 0;
    for (let y = 0; y < N; y++) {
      for (let x = 0; x < N; x++) {
        // if this is the last chunk in x,
        // the width will just be whatever number of pixels is left over.
        const width = x === N - 1 ? lastChunkWidth : standardChunkWidth;
        const height = y === N - 1 ? lastChunkHeight : standardChunkHeight;

        const chunk: Chunk = {
          id: uuidv4(),
          dirty: false,
          index,
          xMin: x * standardChunkWidth,
          yMin: y * standardChunkHeight,
          width,
          height,
        };

        chunks.push(chunk);
        index++;
      }
    }
    return chunks;
  }

  // initFromTemplateAndBlobs initializes the underlying UInt8ClampedArray
  // to the template image. Any blobs that are provided will overwrite their respective
  // chunks.
  async initFromTemplateAndBlobs(
    template: HTMLImageElement,
    blobs: ChunkBlob[]
  ) {
    const startTime = window.performance.now();
    this.data = getImageData(template);

    for (const blob of blobs) {
      const url = URL.createObjectURL(blob.data);
      const image = await loadImage(url);
      const imageData = getImageData(image);

      // write this image data to global data
      let index = 0;
      const { yMin, xMin, width, height } = this.chunks[blob.chunkIndex];
      for (let y = yMin; y < yMin + height; y++) {
        for (let x = xMin; x < xMin + width; x++) {
          const globalIndex = x * 4 + y * 4 * this.width;
          this.data[globalIndex] = imageData[index];
          this.data[globalIndex + 1] = imageData[index + 1];
          this.data[globalIndex + 2] = imageData[index + 2];
          this.data[globalIndex + 3] = imageData[index + 3];
          index += 4;
        }
      }

      // set the chunk's id
      this.chunks[blob.chunkIndex].id = blob.id;
    }

    console.log("assembled in (ms): ", window.performance.now() - startTime);
  }

  magicallyFinishPainting(preview: HTMLImageElement) {
    const imageData = getImageData(preview);
    let index = 0;
    const yMin = 0;
    const xMin = 0;
    const width = this.width;
    const height = this.height;
    for (let y = yMin; y < yMin + height; y++) {
      for (let x = xMin; x < xMin + width; x++) {
        const globalIndex = x * 4 + y * 4 * this.width;
        this.data[globalIndex] = imageData[index];
        this.data[globalIndex + 1] = imageData[index + 1];
        this.data[globalIndex + 2] = imageData[index + 2];
        this.data[globalIndex + 3] = imageData[index + 3];
        index += 4;
      }
    }
  }

  private imageDataForChunk(chunk: Chunk): Uint8ClampedArray {
    const length = chunk.width * chunk.height * 4;
    const data = new Uint8ClampedArray(length);

    let index = 0;
    for (let y = chunk.yMin; y < chunk.yMin + chunk.height; y++) {
      for (let x = chunk.xMin; x < chunk.xMin + chunk.width; x++) {
        // look up the global index
        const globalIndex = x * 4 + y * 4 * this.width;
        data[index] = this.data[globalIndex];
        data[index + 1] = this.data[globalIndex + 1];
        data[index + 2] = this.data[globalIndex + 2];
        data[index + 3] = this.data[globalIndex + 3];
        index += 4;
      }
    }

    return data;
  }

  async getBlobsForDirtyChunks(): Promise<
    { id: string; chunkIndex: number; buffer: Uint8Array }[]
  > {
    return await Promise.all(
      this.chunks
        .filter((chunk) => chunk.dirty)
        .map(async (chunk) => {
          const imageData = this.imageDataForChunk(chunk);
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d")!;
          canvas.width = chunk.width;
          canvas.height = chunk.height;
          const canvasImage = ctx.createImageData(chunk.width, chunk.height);
          canvasImage.data.set(imageData);
          ctx.putImageData(canvasImage, 0, 0);
          const buffer = await getArrayBufferFromCanvas(canvas);
          return { id: chunk.id, chunkIndex: chunk.index, buffer };
        })
    );
  }

  // Set a chunk dirty after painting
  // but I need a way to calculate the chunk index based off the brush position and size
  setChunksDirty(brushX: number, brushY: number, brushSize: number) {
    this.chunks.forEach((chunk) => {
      const { yMin, xMin, width, height } = chunk;

      if (
        pointInRect(brushX, brushY - brushSize, xMin, yMin, width, height) ||
        pointInRect(brushX, brushY + brushSize, xMin, yMin, width, height) ||
        pointInRect(brushX - brushSize, brushY, xMin, yMin, width, height) ||
        pointInRect(brushX + brushSize, brushY, xMin, yMin, width, height)
      ) {
        chunk.dirty = true;
      }
    });
  }

  // Called after a successful save!
  setChunkClean(chunkIndex: number) {
    this.chunks[chunkIndex].dirty = false;
  }
}
