import { vec3, mat4 } from "gl-matrix";

export const MinCameraDistance = 0.1;
export const MaxCameraDistance = 2.5;

class Camera {
  eye: vec3;
  center: vec3;
  up: vec3;
  viewMatrix: mat4;

  constructor() {
    this.reset();
  }
  reset = () => {
    this.eye = [0, 0, 1] as vec3;
    this.center = [0, 0, 0];
    this.up = [0, 1, 0];
    this.viewMatrix = mat4.create();
    this.update();
  };

  zoom = (dz) => {
    this.eye[2] += dz;
    this.eye[2] = Math.max(this.eye[2], MinCameraDistance);
    this.eye[2] = Math.min(this.eye[2], MaxCameraDistance);
    this.update();
  };

  pan = (dx, dy) => {
    this.eye[0] += dx;
    this.eye[1] += dy;
    this.center[0] += dx;
    this.center[1] += dy;
    this.update();
  };

  update() {
    mat4.lookAt(this.viewMatrix, this.eye, this.center, this.up);
  }
}

export default new Camera();
