import React, { FunctionComponent } from "react";
import { api } from "../api";
import WithRouter, { WithRouterProps } from "../../common/WithRouter";
import { Link } from "react-router-dom";
import { ContinueWithGoogleSection } from "../components/googleAuth";
import { PriceString, TrialOptions, goToCheckout } from "../checkout";
import { Features } from "../components/Pricing";

interface SignUpPageProps {
  setAuthenticated: () => void;
}

class SignUpPage extends React.Component<SignUpPageProps & WithRouterProps> {
  state = {
    email: "",
    emailConf: "",
    password: "",
    loading: false,
  };

  signup = () => {
    if (!this.state.email.trim() || !this.state.emailConf.trim()) {
      alert("Please enter an email and password.");
      return;
    }

    if (this.state.email !== this.state.emailConf) {
      alert("Please confirm your email and try again.");
      return;
    }

    this.setState({ loading: true });

    setTimeout(() => {
      api
        .requestSignUp({
          email: this.state.email,
          password: this.state.password,
        })
        .then((res) => {
          if (res.message === "user_exists") {
            // TODO: handle user already exists
            alert("A user with this email already exists.");
            console.warn("user already exists", this.state.email);
            return;
          }

          if (res.message === "ok") {
            console.log("new user created");
            goToCheckout(TrialOptions.NoTrial);
          }
        })
        .finally(() => {
          this.setState({ loading: false });
        });
    }, 1000);
  };

  render() {
    const isValid = this.state.email !== "" && this.state.password !== "";
    const headerText = "✨ 👋 We think you'll like it here!";
    const subtext = `${PriceString}/ mo. Ad free. Cancel anytime.`;
    const alternate = (
      <Link to="/gallery">
        <p>Still thinking? Explore the gallery</p>
      </Link>
    );

    return (
      <div className="container-fluid text-center">
        <div className="row">
          {/* sign up form */}
          <div className="col-md-6 my-5">
            <div className="row justify-content-center mb-3">
              <div className="col-md-10">
                <h2>
                  <strong>{headerText}</strong>
                </h2>
                <p className="mt-2">{subtext}</p>
                {alternate}
              </div>
            </div>

            {/* TODO: separate the email/password from from the pricing info */}

            <div className="row justify-content-center">
              <div className="col-md-10">
                <div className="form-floating mb-3">
                  <input
                    type="email"
                    className="form-control form-control-sm"
                    id="email"
                    placeholder="name@example.com"
                    onChange={(e) =>
                      this.setState({ email: e.currentTarget.value })
                    }
                  />
                  <label htmlFor="email">Email</label>
                </div>
                <div className="form-floating mb-3">
                  <input
                    type="email"
                    className="form-control form-control-sm"
                    id="email-conf"
                    placeholder="name@example.com"
                    onChange={(e) =>
                      this.setState({ emailConf: e.currentTarget.value })
                    }
                  />
                  <label htmlFor="email-conf">Confirm Email</label>
                </div>
                <div className="form-floating">
                  <input
                    type="password"
                    className="form-control form-control-sm"
                    id="floatingPassword"
                    placeholder="Choose a password"
                    onChange={(e) =>
                      this.setState({ password: e.currentTarget.value })
                    }
                  />
                  <label htmlFor="floatingPassword">Choose a password</label>
                </div>
              </div>
            </div>
            <div className="row justify-content-center mt-3">
              <div className="col-md-10">
                <button
                  disabled={!isValid}
                  className="btn btn-primary btn-lg w-100"
                  onClick={(e) => {
                    this.signup();
                  }}
                >
                  {this.state.loading ? (
                    <div className="d-flex justify-content-center">
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  ) : (
                    <>
                      <span
                        className="text-light"
                        style={{
                          float: "left",
                          marginTop: "3px",
                        }}
                      >
                        <i className="bi bi-lock-fill"></i>
                      </span>

                      <span className="fs-6" style={{ marginLeft: "-20px" }}>
                        Continue to payment info{" "}
                        <i className="bi bi-arrow-right"></i>
                      </span>
                    </>
                  )}
                </button>
              </div>
            </div>

            <ContinueWithGoogleSection
              colClass="col-md-10"
              text={"signup_with"}
              router={this.props.router}
              setAuthenticated={this.props.setAuthenticated}
            />
          </div>

          {/* image */}
          <div
            className="col-md-6 g-0"
            style={{
              position: "relative",
            }}
          >
            <div
              className="text-light"
              style={{
                position: "absolute",
                top: 50,
                left: 0,
                right: 0,
                zIndex: "1001",
              }}
            >
              <div className="row justify-content-center text-start g-0">
                <div className="col-10">
                  <div className="row">
                    <h4 className="mb-5">What's Included:</h4>
                    <Features variant="light" fullDetails={false} />
                  </div>
                </div>
              </div>

              {/* <blockquote className="blockquote">
                <div className="mb-2">
                  <i
                    style={{ fontSize: "96px", opacity: 0.2 }}
                    className="bi bi-quote"
                  ></i>
                </div>
                <p className="fst-italic">
                  Oasis PBN really helps me chill out after a long day.
                </p>
                <p style={{ color: "rgba(255,255,255,.75)" }}>
                  <small>Josh G. New York</small>
                </p>
              </blockquote> */}
            </div>

            <div
              style={{
                width: "100%",
                height: "calc(100vh - 48px)",
                position: "absolute",
                zIndex: "1000",
                background:
                  "linear-gradient(0deg, rgba(0,0,0,0.9) 0%, rgba(0,0,0,.5) 100%)",
              }}
            ></div>
            <img
              style={{
                width: "100%",
                objectFit: "cover",
                height: "calc(100vh - 48px)",
              }}
              src="https://pbnassets-dev.nyc3.cdn.digitaloceanspaces.com/preview/9cf7201c-e27d-4c7c-b046-16cf6952e2e7-preview.png"
            ></img>
          </div>
        </div>
      </div>
    );
  }
}

// Create a new user with email and password
// If email already exists, early exit.
// After user is succsessfully created,
// redirect to create-checkout-session

export default WithRouter<SignUpPageProps>(SignUpPage);
