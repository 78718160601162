// Local
export const HostUrl = process.env.APP_URL || "http://localhost:1234"
export const ServerUrl = process.env.APP_URL || "http://localhost:8000"
export const CORSOrigin = HostUrl;

// iPad
// export const HostUrl = "http://192.168.160.194:8000";
// export const ServerUrl = "http://192.168.160.194:8000";
// export const CORSOrigin = "http://192.168.160.194:1234";

export const shouldEnableTelemetry = () => {
  return HostUrl.includes("https://oasispbn.com");
};


console.log("Host URL:", HostUrl);
console.log("Server URL:", ServerUrl);
console.log("Cors Origin:", CORSOrigin);
