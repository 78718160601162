import React from "react";
import camera from "./camera";

export type CanvasMode = "brush" | "eraser" | "pan";

interface ModeButtonProps {
  displayMode: "large" | "small";
  mode: CanvasMode;
  setMode: (mode: CanvasMode) => void;
}

export function ModeButtons(props: ModeButtonProps) {
  const { mode, setMode, displayMode } = props;

  const isLarge = displayMode === "large";
  const largeClass = isLarge ? "btn-lg" : "";
  const modeColClass = isLarge ? "col-12" : "col-7";
  const cameraControlsColClass = isLarge ? "col-12 mt-3" : "col-5";
  return (
    <div className="row">
      <div className={modeColClass}>
        <div className="btn-group w-100">
          <button
            title="pan mode"
            className={`btn ${largeClass} btn-outline-primary ${
              mode === "pan" && "active"
            }`}
            onClick={() => setMode("pan")}
          >
            <i className="bi bi-arrows-move"></i>
          </button>
          <button
            title="brush mode"
            className={`btn ${largeClass} btn-outline-primary ${
              mode === "brush" && "active"
            }`}
            onClick={() => setMode("brush")}
          >
            <i className="bi bi-brush-fill"></i>
          </button>
          <button
            title="eraser mode"
            className={`btn ${largeClass} btn-outline-primary ${
              mode === "eraser" && "active"
            }`}
            onClick={() => setMode("eraser")}
          >
            <i className="bi bi-eraser-fill"></i>
          </button>
        </div>
      </div>

      <div className={cameraControlsColClass}>
        <div className="d-flex justify-content-between">
          <button
            className="btn btn-outline-secondary btn w-100"
            title="reset camera"
            onClick={() => camera.reset()}
          >
            <i className="bi bi-arrows-angle-contract"></i>
          </button>
          <button
            className="btn btn-outline-secondary btn w-100 ms-2 me-2"
            title="zoom in"
            onClick={() => camera.zoom(-0.1)}
          >
            <i className="bi bi-zoom-in"></i>
          </button>
          <button
            className="btn btn-outline-secondary btn w-100"
            title="zoom out"
            onClick={() => camera.zoom(0.1)}
          >
            <i className="bi bi-zoom-out"></i>
          </button>
        </div>
      </div>
    </div>
  );
}
