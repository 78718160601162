export default function annulus() {
  const radius = 0.53;
  const innerRadius = 0.5;
  const segments = 32;
  const innerSegments = 16;
  const thetaOffset = 0;

  const theta = Math.PI * 2;
  const size = (segments + 1) * (innerSegments + 1);
  const positions = new Float32Array(size * 3);
  const cells = new Uint16Array(size * 6);

  let vertexIndex = 0;
  let cellIndex = 0;

  for (let j = 0; j <= innerSegments; j++) {
    const r = innerRadius + (radius - innerRadius) * (j / innerSegments);

    for (let i = 0; i <= segments; i++, vertexIndex++) {
      const t = (i / segments) * theta + thetaOffset;

      const cosTheta = Math.cos(t);
      const sinTheta = Math.sin(t);

      const x = r * cosTheta;
      const y = r * sinTheta;

      positions[vertexIndex * 3] = x;
      positions[vertexIndex * 3 + 1] = y;

      if (i < segments && j < innerSegments) {
        const a = j * (segments + 1) + i;
        const b = a + segments + 1;
        const c = a + segments + 2;
        const d = a + 1;

        cells[cellIndex] = a;
        cells[cellIndex + 1] = b;
        cells[cellIndex + 2] = d;

        cells[cellIndex + 3] = b;
        cells[cellIndex + 4] = c;
        cells[cellIndex + 5] = d;

        cellIndex += 6;
      }
    }
  }

  return { positions, cells };
}
