import { ServerUrl } from "../server/config";
import {
  AddTemplateRequest,
  API,
  AuthenticateRequest,
  AuthenticateResponse,
  CreateCheckoutSessionRequest,
  CreateCustomerPortalSessionRequest,
  DemoPaintingRequest,
  DemoPaintingResponse,
  FulfillPurchaseRequest,
  FulfillPurchaseResponse,
  NewPaintingRequest,
  NewPaintingResponse,
  PaintingRequest,
  PaintingResponse,
  PaintingsRequest,
  PaintingsResponse,
  PaintingTemplatesRequest,
  PaintingTemplatesResponse,
  PurchaseRequest,
  PurchaseResponse,
  SaveChunksRequest,
  SavePaintingRequest,
  SavePaintingResponse,
  SendActivityRequest,
  SignUpRequest,
  UpdateTemplateRequest,
} from "../common/interfaces";

const Endpoints = {
  authenticate: "api/authenticate",
  signOut: "api/signout",
  requestPurchase: "api/purchase",
  requestFulfillPurchase: "api/fulfill",
  requestUserPainting: "api/user-painting",
  requestDemoPainting: "api/demo-painting",
  requestPaintings: "api/paintings",
  requestTemplates: "api/templates",
  requestNew: "api/new",
  requestSave: "api/save",
  updateTemplate: "api/update-template",
  addTemplate: "api/add-template",
  requestSignup: "api/sign-up",
  requestCreateCheckoutSession: "api/create-checkout-session",
  requestCreateCustomerPortalSession: "api/create-customer-portal-session",
  subscriptionStatus: "api/subscription-status",
  activity: "api/activity",
};

function fetchAndThrowErrors(endpoint, options) {
  return fetch(endpoint, options).then((res) => {
    if (!res.ok) {
      if (res.status === 401) {
        // Force a reload and get redirected to /sign-in.
        window.location.href = "/sign-in"
      }

      throw Error(res.status.toString());
    }
    return res;
  });
}

function post(endpoint: string, body: any) {
  return fetchAndThrowErrors(endpoint, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(body),
    credentials: "include",
  });
}

function get(endpoint: string) {
  return fetchAndThrowErrors(endpoint, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
    credentials: "include",
  });
}

export const api: API = {
  requestRefreshSession: async () => {
    await post(`${ServerUrl}/api/refresh-session`, {});
  },
  authenticate: async (
    req: AuthenticateRequest
  ): Promise<AuthenticateResponse> => {
    return await post(`${ServerUrl}/${Endpoints.authenticate}`, req).then(
      (res) => res.json()
    );
  },
  authenticateWithGoogle: async (req: { credential: string }) => {
    return await post(`${ServerUrl}/api/gauth`, req).then((res) => res.json());
  },
  signOut: async () => {
    return await post(`${ServerUrl}/${Endpoints.signOut}`, {}).then((res) =>
      res.json()
    );
  },
  requestPurchase: async (req: PurchaseRequest): Promise<PurchaseResponse> => {
    return await post(`${ServerUrl}/${Endpoints.requestPurchase}`, req).then(
      (res) => res.json()
    );
  },
  requestFulfillPurchase: async (
    req: FulfillPurchaseRequest
  ): Promise<FulfillPurchaseResponse> => {
    return await post(
      `${ServerUrl}/${Endpoints.requestFulfillPurchase}`,
      req
    ).then((res) => res.json());
  },

  requestDemoPainting: async (
    req: DemoPaintingRequest
  ): Promise<DemoPaintingResponse> => {
    const url = `${ServerUrl}/${Endpoints.requestDemoPainting}?id=${req.templateId}`;
    return await get(url).then((res) => res.json());
  },

  // Get a user's painting
  requestUserPainting: async (
    req: PaintingRequest
  ): Promise<PaintingResponse> => {
    const url = `${ServerUrl}/${Endpoints.requestUserPainting}?id=${req.paintingId}`;
    return {
      buffer: await get(url).then((res) => res.arrayBuffer()),
    };
  },

  // Get a user's saved paintings
  requestPaintings: async (
    req: PaintingsRequest
  ): Promise<PaintingsResponse> => {
    const endpoint = `${ServerUrl}/${Endpoints.requestPaintings}`;
    return await get(endpoint).then((res) => res.json());
  },
  requestTemplates: async (
    req: PaintingTemplatesRequest
  ): Promise<PaintingTemplatesResponse> => {
    const endpoint = `${ServerUrl}/${Endpoints.requestTemplates}`;
    return await get(endpoint).then((res) => res.json());
  },
  requestNewPainting: async (
    req: NewPaintingRequest
  ): Promise<NewPaintingResponse> => {
    return await post(`${ServerUrl}/${Endpoints.requestNew}`, req).then((res) =>
      res.json()
    );
  },
  requestSavePainting: async (
    req: SavePaintingRequest
  ): Promise<SavePaintingResponse> => {
    return await fetchAndThrowErrors(
      `${ServerUrl}/${Endpoints.requestSave}/${req.paintingId}`,
      {
        method: "POST",
        body: req.buffer,
        credentials: "include",
        headers: {
          "Content-Type": "application/octet-stream",
        },
      }
    ).then((res) => res.json());
  },

  requestSignUp: async (req: SignUpRequest) => {
    const endpoint = `${ServerUrl}/${Endpoints.requestSignup}`;
    return await post(endpoint, req).then((res) => res.json());
  },

  requestCreateCheckoutSession: async (req: CreateCheckoutSessionRequest) => {
    const endpoint = `${ServerUrl}/${Endpoints.requestCreateCheckoutSession}`;
    return await post(endpoint, req).then((res) => res.json());
  },

  requestCreateCustomerPortalSession: async (
    req: CreateCustomerPortalSessionRequest
  ) => {
    const endpoint = `${ServerUrl}/${Endpoints.requestCreateCustomerPortalSession}`;
    return await post(endpoint, req).then((res) => res.json());
  },

  // Telemetry:
  requestSendActivity: async (req: SendActivityRequest) => {
    return await post(`${ServerUrl}/${Endpoints.activity}`, req).then(res => res.json());
  },

  // ADMIN ROUTES
  requestAddTemplate: async (req: AddTemplateRequest) => {
    return await post(`${ServerUrl}/${Endpoints.addTemplate}`, req).then(
      (res) => res.json()
    );
  },
  requestUpdateTemplate: async (req: UpdateTemplateRequest) => {
    return await post(`${ServerUrl}/${Endpoints.updateTemplate}`, req).then(
      (res) => res.json()
    );
  },
  requestSubscriptionStatus: async () => {
    return await get(`${ServerUrl}/${Endpoints.subscriptionStatus}`).then(
      (res) => res.json()
    );
  },
};
