import { vec3, vec4, mat4 } from "gl-matrix";

export default function unproject(
  displayX: number,
  displayY: number,
  projection: mat4,
  view: mat4,
  canvasWidth: number,
  canvasHeight: number
) {
  // normalized device coordinates
  const rayNDS = vec3.fromValues(
    (2 * displayX) / canvasWidth - 1.0,
    1.0 - (2 * displayY) / canvasHeight,
    1.0
  );

  // homogeneous clip coordinates
  const rayClip = vec4.fromValues(rayNDS[0], rayNDS[1], -1.0, 1.0);

  // eye coordinates
  const rayEye = vec4.transformMat4(
    vec4.create(),
    rayClip,
    mat4.invert(mat4.create(), projection)
  );
  rayEye[2] = -1.0;
  rayEye[3] = 0.0;

  // world coordinates
  const rayWorld = vec4.transformMat4(
    vec4.create(),
    rayEye,
    mat4.invert(mat4.create(), view)
  );
  const rayDirection = vec3.normalize(vec3.create(), [
    rayWorld[0],
    rayWorld[1],
    rayWorld[2],
  ]);

  return rayDirection;
}
