import React from "react";

export class AboutPage extends React.Component {
  render() {
    return (
      <div className="container" style={{ minHeight: "700px" }}>
        <div className="row mt-5 justify-content-center">
          <div className="col-md-6">
            <p className="lead mb-5">
              Hi, we’re Zach and Sarah! We’re husband and wife who found solace
              in paint-by-numbers during the pandemic.
            </p>
            <p className="mb-5">
              We fell in love with the relaxing, meditative process of painting
              beautiful pictures. We could listen to music or an audiobook,
              while creating something with our hands.
            </p>
            <p className="mb-5">
              We began our paint-by-numbers journey with physical kits, but with
              each kit costing up to $30 this quickly became an expensive hobby. In
              addition to the expense, the paints were a pain. They would often
              dry out, or worse - stain our clothes!
            </p>
            <p className="mb-5">
              <strong>
                We created Oasis PBN because we wanted to provide a cheaper,
                more convenient, and more accessible alternative to physical
                paint-by-numbers kits.
              </strong>
            </p>
            <p className="mb-5">
              Zach is a software engineer, and Sarah is a user experience
              researcher. Together, we used our tech and design chops
              to build something that brings you the same joy and peace that it
              has brought us.
            </p>
            <img
              style={{ width: "100%" }}
              className="mb-5"
              src="https://pbnassets-dev.nyc3.cdn.digitaloceanspaces.com/web-assets%2Fzach-and-sarah.jpg"
            ></img>
          </div>
        </div>
      </div>
    );
  }
}
