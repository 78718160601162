import React from "react";
import { SubscriptionStatus } from "../../common/interfaces";
import { TrialOptions, goToCheckout } from "../checkout";
import { goToCustomerPortal } from "../customerPortal";

export class AccountPage extends React.Component<{
  subscriptionStatus: SubscriptionStatus;
}> {
  render() {
    return (
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8 mt-5">
            {/* If the user has no subscription, do nothing */}

            {this.props.subscriptionStatus !==
              SubscriptionStatus.SubscriptionActive && (
              <div className="alert alert-warning">
                <i className="bi bi-exclamation-triangle-fill"></i>
                <span className="ms-2">
                  There is no active subscription for this account.
                </span>
                <button
                  className="btn btn-outline-primary ms-4"
                  onClick={() => {
                    goToCheckout(TrialOptions.NoTrial);
                  }}
                >
                  Subscribe
                </button>
              </div>
            )}

            {this.props.subscriptionStatus ===
              SubscriptionStatus.SubscriptionActive && (
              <div className="alert alert-success">
                <i className="bi bi-check-circle-fill"></i>
                <span className="ms-2">You are subscribed</span>
                <button
                  type="button"
                  className="btn btn-link ms-4"
                  onClick={() => {
                    goToCustomerPortal();
                  }}
                >
                  Manage subscription
                </button>
              </div>
            )}

            <p>For support, please email us at support@oasispbn.com</p>
          </div>
        </div>
      </div>
    );
  }
}
