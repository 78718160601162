import { GoogleLogin } from "@react-oauth/google";
import React from "react";
import { WithRouterProps } from "../../common/WithRouter";
import { ServerUrl } from "../../server/config";
import { api } from "../api";
import { TrialOptions, goToCheckout } from "../checkout";
import { signinSuccess } from "../signinSuccess";

interface GoogleAuthProps {
  colClass: string;
  text: "signin_with" | "signup_with";
  router: WithRouterProps["router"]
  setAuthenticated: () => void;
}

async function googleAuth(credential: string) {
  return await api.authenticateWithGoogle({
    credential: credential,
  });
}

export function ContinueWithGoogleSection(props: GoogleAuthProps) {
  return (
    <>
      <div className="row align-items-center text-center justify-content-center py-4">
        <div className={props.colClass}>
          <hr />
        </div>
      </div>

      <div className="row justify-content-center">
        <div className={props.colClass}>
          <GoogleLogin
            login_uri={`${ServerUrl}/api/gauth`}
            theme="filled_blue"
            ux_mode="popup"
            text={props.text}
            onSuccess={(credentialResponse) => {
              googleAuth(credentialResponse.credential!).then((res) => {
                if (res.isNewUser) {
                  goToCheckout(TrialOptions.NoTrial);
                } else {
                  props.setAuthenticated();
                  signinSuccess(props.router)
                }
              });
            }}
            onError={() => {
              console.log("Login Failed");
            }}
          />
        </div>
      </div>
    </>
  );
}
